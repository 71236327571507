.header {
    position: sticky;
    top: 0;
    z-index: 1000;
    display: flex;
    height: 64px; /* Set a standard height for better alignment */
    padding: 12px 24px; /* Adjust the padding */
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--black-5, rgba(28, 28, 28, 0.05));
    background-color: #fff;
}

.header__left {
    display: flex;
    align-items: center;
}

.header__left a {
    display: inline-block;
    cursor: pointer;
}

.header__logo {
    width: 117px; /* Adjust as needed */
    height: 28px; /* Adjust as needed */
    margin-left: 8px; /* Space between vector and logo */
}

.header__logo:hover {
    opacity: 0.8;
}

.header__vector {
    width: 28px; /* Adjust as needed */
    height: 28px; /* Adjust as needed */
}

.header__middle {
    display: flex;
    justify-content: center;
    align-items: center;
  }

.header__search {
    display: flex;
    align-items: center;
    background-color: var(--Primary-Light, #F9FAFC);
    border-radius: 8px;
    padding: 8px 12px;
    width: 470px; /* Adjust as needed */
}

.header__search-icon {
    width: 16px; /* Adjust as needed */
    height: 16px; /* Adjust as needed */
    margin-right: 8px;
}

.header__search input {
    border: none;
    outline: none;
    background: none;
    font-size: 14px;
    color: #1c1c1c;
    flex-grow: 1; /* Allow input to take remaining space */
}

.header__right {
    display: flex;
    align-items: center;
}

.header__icon {
    width: 24px; /* Adjust as needed */
    height: 24px; /* Adjust as needed */
    margin-left: 24px;
    cursor: pointer;
}

.header__burger-icon {
    display: none;
}

.header__managing-company {
    display: flex;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: rgba(255, 203, 131, 0.40);
  }
  
  .exit-org-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    color: #009A6B;
    text-align: center;
    font-feature-settings: 'ss01' on, 'cv01' on, 'cv11' on;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px 8px;
  }
  
  .exit-icon {
    width: 16px;
    height: 16px;
    filter: invert(38%) sepia(90%) saturate(1080%) hue-rotate(127deg) brightness(95%) contrast(101%);
  }

  .exit-org-button:hover .exit-icon {
    filter: invert(38%) sepia(90%) saturate(1080%) hue-rotate(127deg) brightness(75%) contrast(101%);
  }

@media (max-width: 768px) {
    .header {
        padding: 12px 12px;
    }

    .header img {
        margin-left: 0px;
    }

    .header__left a {
        height:28px;
    }

    .header__icon:not(.header__burger-icon) {
        display: none;
    }

    .header__burger-icon {
        display: block;
        color: #1C1C1CCC
    }
}
