.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: #fff;
    padding: 28px;
    border-radius: 20px;
    width: 600px;
    max-width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
}

.close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.modal-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: flex-start; */
    gap: var(--4, 4px);
    flex: 1 0 0;
    border-radius: var(--8, 8px);
    width: 100%;
}

.modal-title {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    color: #1C1C1C;
    /* align to the center */
    text-align: center;
    color: var(--black-100, #1C1C1C);
    text-align: center;

    /* Display sm/Semibold */
    font-family: var(--Font-family-font-family-display, Inter);
    font-size: var(--Font-size-display-sm, 30px);
    font-style: normal;
    font-weight: 600;
    line-height: var(--Line-height-display-sm, 38px); /* 126.667% */
    
}

.modal-subtitle {
    color: var(--black-40, rgba(28, 28, 28, 0.40));
    font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.modal-subtitle .link {
    color: var(--Primary-Brand, #009A6B);
    cursor: pointer;
    font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.modal-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* gap: 16px; */
}

.modal-body .success-message {
    color: #009A6B;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-top: 16px;
    text-align: center;
}

.modal-body .analytics-update-message {
    color: #009A6B;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
}


.upload-section {
    border: 2px dashed #009A6B;
    border-radius: 12px;
    padding: 28px;
    text-align: center;
    height: 200px;
    flex-shrink: 0;
    border-radius: var(--16, 16px);
    border: 2px dashed var(--Primary-MediumGreen, #79CD8F);
    background: rgba(190, 231, 198, 0.20);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.upload-icon {
    position: relative; /* Make this container relative */
    display: flex;
    justify-content: center;
    align-items: center;
}


.upload-icon2 {
    position: relative;
    bottom: -30px;
    right: 20px;  
    padding: 6px;
    justify-content: center;
    align-items: center;
    border-radius: var(--20, 20px);
    background: var(--Primary-Brand, #009A6B);
}

.upload-section .upload-text {
    margin: 8px 0;
    font-size: 14px;
    color: var(--black-80, rgba(28, 28, 28, 0.80));
    text-align: center;
    font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    
}

.upload-section .link {
    color: #009A6B;
    cursor: pointer;
    margin-left: 4px;
}

.upload-footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 12px;
    color: #888;
    margin-top: 4px;
}

.supported-formats,
.max-size {
    margin: 0;
}

.progress-section {
    padding: 16px;
    border: 1px solid #E4E7EC;
    border-radius: var(--16, 16px);
    border: 1px solid var(--black-5, rgba(28, 28, 28, 0.05));
    background: var(--Primary-Light, #F9FAFC);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
}



.file-details {
    display: flex;
    align-items: center;
    gap: 16px;
}

.file-icon {
    width: 40px;
    height: 40px;
    background: #E4E7EC;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.file-name-size {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.file-name {
    font-weight: 600;
    color: #1C1C1C;
}

.file-size {
    text-align: left;
    color: var(--black-40, rgba(28, 28, 28, 0.40));
    font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
}

.progress-bar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.progress-bar {
    width: 92%;
    background: #fff; /* Ensure the background is white */
    border-radius: 4px;
    overflow: hidden;
    height: 8px;
}

.progress-bar .progress {
    height: 100%;
    background: #009A6B;
    border-radius: 4px;
}

.percentage {
    font-size: 12px;
    color: #888;
    margin-left: 8px;
    color: var(--black-80, rgba(28, 28, 28, 0.80));
    font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    padding: 0; /* Remove any padding */
    height: 8px; /* Ensure the height matches the progress bar */
}

.file-count {
    flex: 1; /* Allow file-count to take up available space */
    font-size: 12px;
    color: #888;
    text-align: right;
}

.modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 16px 0; /* Add some padding to the top and bottom */
    gap: 16px; /* Add space between items */
}

.cancel-button,
.upload-button {
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s, background 0.3s; /* Add transition for smooth effect */
}


.cancel-button {
    background: var(--black-5, rgba(28, 28, 28, 0.05));
    color: #1C1C1C;
}

.cancel-button:hover {
    background-color: rgba(28, 28, 28, 0.1);
}


.upload-button {
    background: #009A6B;
    color: #fff;
}

.upload-button:disabled {
    background: #d3d3d3; /* Grey background */
    cursor: not-allowed;
    color: #fff; /* White text */
}

.upload-button:disabled:hover {
    background: #d3d3d3; /* Keep the same background for disabled state */
}


.upload-button:hover {
    background: linear-gradient(0deg, rgba(28, 28, 28, 0.20) 0%, rgba(28, 28, 28, 0.20) 100%), #009A6B;
}


.modal-body.dragging .upload-section {
    border: 2px dashed #007bff;
    background-color: #f0f8ff;
}


.file-list {
    display: flex-start;
    flex-direction: column;
    border: 2px solid var(--black-5, rgba(28, 28, 28, 0.05));
    border-radius: var(--16, 16px);
    padding: 28px;
    flex-shrink: 0;
    background: var(--Primary-Light, #F9FAFC);
    width: 100%; /* Make it wider */
    height: 200px; /* Reduce the height */
    overflow-y: auto; /* Add overflow to handle many files */
}

.file-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    padding: var(--8, 8px) var(--12, 12px) var(--8, 8px) 0px;
    min-height: 40px;
    border-radius: var(--8, 0px);
    border-bottom: 1px solid var(--black-5, rgba(28, 28, 28, 0.05));
}

.file-upload-info {
    display: flex;
    flex-direction: column;
    gap: 2px;
    flex:content;
    max-width: calc(100% - 100px); /* Adjust based on the width of the status area */

}

.file-name {
    color: var(--black-100, #1C1C1C);
    font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 150% */
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.file-status {
    display: flex;
    align-items: center; /* Ensure items are centered vertically */
    font-size: 12px;
    font-weight: 600;
    text-align: right;
}

.file-status.done {
    color: #009A6B;
    gap: 8px;
}

.done-icon {
    width: 16px;
    height: 16px;
}


.file-status.pending {
    color: var(--black-80, rgba(28, 28, 28, 0.80));
    text-align: right;
    font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
    /* 12 Regular */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
}

.file-status.uploading {
    color: var(--black-80, rgba(28, 28, 28, 0.80));
    text-align: right;
    font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
    /* 12 Regular */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
}


.invoice-type-selector {
    display: flex;
    background: var(--Primary-Light, #F9FAFC);
    border-radius: var(--radius-lg, 10px);
    border: 1px solid var(--black-5, rgba(28, 28, 28, 0.05));
    justify-content: center;
    align-items: center;
    gap: var(--spacing-xs, 4px);
    align-self: stretch;
    padding: var(--spacing-xs, 4px);
    margin-bottom: 8px;
    width: 100%;
  }
  
  .invoice-type-selector .selector-button {
    display: flex;
    flex: 1;
    height: 36px;
    padding: var(--spacing-xs, 4px);
    justify-content: center;
    align-items: center;
    gap: var(--spacing-xs, 4px);
    align-self: stretch;
    cursor: pointer;
    border-radius: 6px;
    border: 0px solid var(--black-5, rgba(28, 28, 28, 0.05));
    background-color: var(--Primary-Light, #F9FAFC);
    background: var(--Primary-Light, #F9FAFC);

    color: var(--black-40, rgba(28, 28, 28, 0.40));

    /* Text sm/Semibold */
    font-family: var(--Font-family-font-family-body, Inter);
    font-size: var(--Font-size-text-sm, 14px);
    font-style: normal;
    font-weight: 600;
    line-height: var(--Line-height-text-sm, 20px); /* 142.857% */

    transition: all 0.3s ease;
  }
  
  .invoice-type-selector .selector-button.active {
    box-shadow: 0px 1px 3px 0px var(--Colors-Effects-Shadows-shadow-sm_01, rgba(16, 24, 40, 0.10)), 0px 1px 2px 0px var(--Colors-Effects-Shadows-shadow-sm_02, rgba(16, 24, 40, 0.06));
    border: 1px solid var(--black-5, rgba(28, 28, 28, 0.05));
    background: var(--Colors-Background-bg-primary_alt, #FFF);
    background-color: var(--Colors-Background-bg-primary_alt, #FFF);

    border-radius: var(--radius-sm, 6px);
    color: var(--black-100, #1C1C1C);
    /* Text sm/Semibold */
    font-family: var(--Font-family-font-family-body, Inter);
    font-size: var(--Font-size-text-sm, 14px);
    font-style: normal;
    font-weight: 600;
    line-height: var(--Line-height-text-sm, 20px); /* 142.857% */
    }

    /* hover active selector button */
    .selector-button.active:hover {
        background: var(--black-5, rgba(28, 28, 28, 0.05));
    }

  
  .selector-button:hover:not(.active) {
        background: var(--black-10, rgba(28, 28, 28, 0.10));
  }


  @media (max-width: 768px) {
    .modal-content {
      width: 100%;
      height: 100%;
      border-radius: 0;
      padding: 16px;
    }
  
    .modal-header {
      padding-top: 20px;
    }
  
    .modal-title {
      font-size: 24px;
      line-height: 30px;
    }
  
    .modal-subtitle {
      font-size: 12px;
      line-height: 18px;
    }
    
    .upload-section {
      height: auto;
      min-height: 200px;
      /* padding: 16px; */
    }
  
    .file-list {
        /* max-height: 200px; */
        width: 100%;
        height: auto;
        min-height: 200px;
        border-radius: 16px;
        padding: 16px;
        
        display: flex;
        flex-direction: column;
      
    }
  
    .progress-section {
      padding: 12px;
    }
  
    .modal-footer {
      flex-direction: column-reverse;
      gap: 12px;
    }
  
    .cancel-button,
    .upload-button {
      width: 100%;
    }
  
    .file-count {
      text-align: center;
      margin-bottom: 8px;
    }
  }