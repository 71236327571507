.view-edit-invoice-modal {
    position: fixed;
    top: 0;
    right: 0;
    width: 690px;
    height: 100vh;
    /* height: calc(100vh - 48px);  */
    background-color: var(--white-100, #FFF);
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.1);
    padding: 24px;
    z-index: 1000;
    box-sizing: border-box;
    overflow: hidden; /* Add this line */

  }
  
.view-edit-invoice-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden; /* Add this line */
    box-sizing: border-box;

}
  
.invoice-header {
    display: flex;
    justify-content: space-between;
    align-items:flex-start;
    border-bottom: 1px solid var(--black-5, rgba(28, 28, 28, 0.05));
    padding-bottom: 16px;
}


.invoice-header-left {
    display: flex;
    flex-direction: column ;
    width: calc(100% - 150px); /* Adjust width to prevent overflow */

}

.invoice-header-left h2 {
    margin: 0;
    display: flex;
    align-items: center;
    gap: 8px; /* Adjust the gap between elements */
}
  

.invoice-header-left h2 .invoice-number {
  color: var(--Primary/Brand, #009A6B);
    font-feature-settings: 'ss01' on, 'cv01' on, 'cv11' on;
    /* 24 Semibold */
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
}

/* Additional styles to ensure color override */
.invoice-header-info h2 span.invoice-number {
  color: var(--Primary-Brand, #009A6B) !important;
}


.supplier-name{
    color: var(--black-40, rgba(28, 28, 28, 0.40));
    /* 16 Medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 8px;
  }

.invoice-header-right {
    display: flex;
    gap: 16px;
    align-items: center;
}

.invoice-header-right .close-button {
    background: none;
    border: none;
    font-size: 16px;
    width: 24px; /* Adjust to match your icon size */
    height: 24px; /* Adjust to match your icon size */
    padding: 0;
    margin: 0;
    top: 10px;
    cursor: pointer;
    color: var(--black-40, rgba(28, 28, 28, 0.40)); /* Change color to grey */
    margin-bottom: 16px; /* Increase margin to create more space between the close button and the edit button */
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
}

.invoice-header-right .close-button:hover {
  background-color: rgba(28, 28, 28, 0.1);
}

.invoice-header-right .prev-button,
.invoice-header-right .next-button {
    display: flex;
    align-items: center;
    gap: 4px; /* Adjust the gap between icon and text as needed */
    background: var(--black-5, rgba(28, 28, 28, 0.05));
    color: rgba(28, 28, 28, 1)  ;
    border-radius: var(--4, 4px);
    cursor: pointer;
    margin-top: 32px;
    border-radius: 8px;
    padding: 8px 10px;
    cursor: pointer;
    font-size: 14px; /* Adjust font size if needed */
    transition: background-color 0.3s;
    border: none;
}

.invoice-header-right .prev-button:hover,
.invoice-header-right .next-button:hover {
    background-color: rgba(28, 28, 28, 0.1);
}

.invoice-header-right .prev-button img,
.invoice-header-right .next-button img{
  opacity: 0.6;
}

.invoice-id {
    color: var(--Primary-Brand, #009A6B);
    font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
  }

   
.invoice-body {
    display: flex;
    flex-direction: column;
    gap: 16px; 

}

/* Edit mode specific styles */
.invoice-details-section.editing .supplier-details {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.invoice-details-section.editing .edit-currency-exchange {
  margin-top: 15px;
}

.invoice-details-section.editing .edit-currency-exchange .invoice-info {
  margin-bottom: 10px;
}

.invoice-details-section.editing .edit-currency-exchange label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.invoice-details-section.editing .edit-currency-exchange select,
.invoice-details-section.editing .edit-currency-exchange input[type="number"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}



.invoice-details-section {
  display: grid;
  grid-template-columns: 2.5fr 0.2fr 1fr 1.2fr;
  gap: 20px;
  align-items: stretch;
}

.supplier-details {
  grid-column: 1;  
}

.spacer {
  grid-column: 2;
}

.issue-date {
  grid-column: 3;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.invoice-actions {
  grid-column: 4;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.edit-currency-exchange {
  margin-top: 15px;
}

.edit-currency-exchange .invoice-info {
  margin-bottom: 10px;
}

.edit-currency-exchange label {
  display: block;
  margin-bottom: 5px;
}

.edit-currency-exchange select,
.edit-currency-exchange input[type="number"] {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}



.invoice-actions .edit-button {
  align-self: flex-end;

}

.invoice-info-bottom {
  margin-top: auto;
}




.invoice-details-section .entity-type-title {
    font-feature-settings: 'ss01' on, 'cv01' on, 'cv11' on;
    /* 14 Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    margin-top: 0;
    margin-bottom: 0;
}

.supplier-details-section .entity-name-section {
    color: var(--black-100, #1C1C1C);
    font-feature-settings: 'ss01' on, 'cv01' on, 'cv11' on;
    
    /* 18 Semibold */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
}

.supplier-details-section .entity-details-section {
    color: var(--black-60, rgba(28, 28, 28, 0.60));
    font-feature-settings: 'ss01' on, 'cv01' on, 'cv11' on;
    
    /* 12 Regular */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
}


.invoice-dates, .edit-invoice-section {
    flex: 1 1 25%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}


.edit-invoice-section {
    align-items: flex-end;
    justify-content: space-between;
}

.edit-invoice-section .edit-button {
    align-self: flex-end;
}

.invoice-info {
    display: flex;
    flex-direction: column;
    justify-content: end;
    height: 100%;
    margin-bottom: 0px !important;
}

.invoice-info-middle{
    display: flex;
    flex-direction: column;
    justify-content: end;
    height: 100%;
   
}
  
.invoice-info label {
    font-weight: bold;
    margin-bottom: 4px;
}
  
.invoice-info p {
    margin: 0 0 8px 0; /* Adjust margin as needed */
}

.invoice-info select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.invoice-info select:focus {
  outline: none;
  border-color: #009a6b;
  box-shadow: 0 0 0 2px rgba(0, 154, 107, 0.2);
}

.invoice-dates {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    /* padding: 16px 8px; */
    border: none;
}

.edit-button {
    color: #FFF;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 0px; 
    align-self: flex-start; 
    transition: background 0.3s;
    display: flex;
    padding: var(--4, 4px) var(--8, 8px);
    justify-content: flex-end;
    align-items: flex-start;
    gap: var(--4, 4px);
    border-radius: var(--4, 4px);
    background: var(--Primary-Brand, #009A6B);

    /* 12 Semibold */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 150% */

}

.edit-button:hover {
  background: linear-gradient(0deg, rgba(28, 28, 28, 0.20) 0%, rgba(28, 28, 28, 0.20) 100%), #009A6B;
}

.items-table-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end; /* Aligns the totals to the right */
    width: 100%;
  }
  
  .items-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;

    /* border-bottom: 1px solid var(--black-5, rgba(28, 28, 28, 0.05)); */
  }
  
  .items-table th, .items-table td {
    text-align: right; /* Adjust as needed */
    padding: 10px 0px;
    border-bottom: none;

  }
  
  .items-table th {
    height: 40px;
    min-height: 40px;
    /* gap: var(--4, 4px); */
    padding: 10px 0px;
    color: var(--black-40, rgba(28, 28, 28, 0.40));
    border-bottom: 1px solid var(--black-20, rgba(28, 28, 28, 0.20));
    font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    text-align: right;
    position: relative; 
  }

  .items-table thead::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: var(--black-20, rgba(28, 28, 28, 0.20));
}

.items-table th:first-child {
  text-align: left;
}

  .items-table tbody tr:last-child td {
    border-bottom: 1px solid var(--black-5, rgba(28, 28, 28, 0.05));
    padding-bottom: 20px;
}

.items-table tfoot tr:last-child td {
    border-top: 1px solid var(--black-5, rgba(28, 28, 28, 0.05));
    padding-top: 20px;
}


  .description-column {
    width: 60%; /* Adjust as needed */
    text-align: left!important;
  }
  
  .price-column {
    width: 15%; /* Adjust as needed */
  }
  
  .quantity-column {
    width: 12%; /* Adjust as needed */
  }
  
  .total-column {
    width: 15%; /* Adjust as needed */
  }
  
  .invoice-totals {
    border-collapse: collapse;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .invoice-total-row {
    display: flex;
    width: 100%;
    padding: 10px 0px;
    color: var(--black-100, #1C1C1C);
    font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
    /* 12 Semibold */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 150% */
  }

  .invoice-total-row .total-label {
    font-weight: bold;
    width: 85%;
  }
  
  .invoice-total-row .total-value {
    margin: 0;
    width: 15%;
    text-align: right; /* Adjust as needed */
  }

  .invoice-total-row .grand-total {
    border-top: 1px solid var(--black-5, rgba(28, 28, 28, 0.05));
    padding-top: 8px;

    font-size: 14px;
    line-height: 20px; /* 142.857% */
  }
  


  .invoice-items {
    gap: var(--4, 4px);
    border-radius: var(--8, 0px);
    color: var(--black-100, #1C1C1C);
    font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
    /* 12 Regular */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    border-radius: var(--16, 16px);
    background: var(--Primary-Light, #F9FAFC);
    display: flex;
    padding: var(--16, 16px);
    flex-direction: column;
    gap: var(--4, 4px);
    align-self: stretch;
  }

  .invoice-items-title {
    color: var(--black-100, #1C1C1C);
    font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
    /* 14 Semibold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
  }

  .invoice-items-header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.currency-toggle {
    display: flex;
    align-items: center;
    gap: 10px;
}

.toggle-label {
    font-size: 14px;
    color: #333;
    color: #000;
    font-feature-settings: 'ss01' on, 'cv01' on, 'cv11' on;

    /* 12 Semibold */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 150% */
}

.switch {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 16px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.currency-switch-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.current-currency {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  min-width: 40px; /* Ensures consistent width for different currency codes */
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 80px;
  display: flex;
  padding: 0px 2px;
  align-items: center;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.10));
}

input:checked + .slider {
  background-color: #009A6B;
}

input:focus + .slider {
  box-shadow: 0 0 1px #009A6B;
}

input:checked + .slider:before {
  transform: translateX(12px);
}

.slider.round {
  border-radius: 80px;
}

.slider.round:before {
  border-radius: 50%;
}



  
.invoice-footer {
    display: flex;
    justify-content: flex-end;
    gap: var(--8, 8px);    
    padding: 20px 0px 0px 0px;
    align-items: center;
    flex-wrap: wrap;
    margin-top: auto;   
    border-top: 1px solid var(--black-5, rgba(28, 28, 28, 0.05));
}
  
.copy-link-button, .download-original-button, .download-pdf-button {
    padding: 8px 16px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    transition: background-color 0.3s, color 0.3s;
}

.copy-link-button:hover {
  background: var(--black-5, rgba(28, 28, 28, 0.05));}

.download-original-button:hover {
  background: var(--black-10, rgba(28, 28, 28, 0.10));
}

.download-pdf-button:hover {
  background: linear-gradient(0deg, rgba(28, 28, 28, 0.20) 0%, rgba(28, 28, 28, 0.20) 100%), #009A6B;
}

.download-pdf-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.download-pdf-button:disabled:hover {
  background: var(--Primary-Brand, #009A6B);
}

.download-original-button:disabled,
.download-pdf-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

  
.copy-link-button {
    background-color: transparent;
    color: var(--Primary-Brand, #009A6B);
}

.download-original-button {
    border-radius: var(--12, 12px);
    background: var(--black-5, rgba(28, 28, 28, 0.05));
    background-color: #f0f0f0;
    color: #000;
}


.download-pdf-button {
    border-radius: var(--12, 12px);
    background: var(--Primary-Brand, #009A6B);
    background-color: var(--Primary-Brand, #009A6B);
    color: #FFF;
    transition: background-color 0.3s, opacity 0.3s;

}

  
.copy-link-button .icon {
    margin-right: 4px; /* Adjust spacing as needed */
    width: 16px; /* Adjust icon size as needed */
    height: 16px; /* Adjust icon size as needed */
    vertical-align: middle; /* Ensures the icon is vertically aligned with the text */

}

.download-original-button .original-icon {
    margin-right: 4px; /* Adjust spacing as needed */
    width: 20px; /* Adjust icon size as needed */
    height: 20px; /* Adjust icon size as needed */
    vertical-align: middle; /* Ensures the icon is vertically aligned with the text */
    border-radius: var(--8, 8px);
}

.download-pdf-button .pdf-icon {
    margin-right: 4px; /* Adjust spacing as needed */
    width: 20px; /* Adjust icon size as needed */
    height: 20px; /* Adjust icon size as needed */
    vertical-align: middle; /* Ensures the icon is vertically aligned with the text */
    filter: invert(1) grayscale(1) brightness(2); /* Changes icon color to white */
    border-radius: var(--8, 8px);
    position: relative;
    margin-left: 0px;
}
  
  
  /* Edit mode css */

/* Scoped styles for edit mode */
.view-edit-invoice-modal.editing .invoice-info input,
.view-edit-invoice-modal.editing .items-table input {
    border: 1px solid var(--black-20, rgba(28, 28, 28, 0.20));
    padding: 12px 8px;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
    border-radius: var(--8, 8px);
    border: 1px solid var(--black-10, rgba(28, 28, 28, 0.10));
    background: var(--white-80, rgba(255, 255, 255, 0.80));
    display: flex;
    min-height: var(--Size-28, 28px);
    align-items: center;
    align-content: center;
    gap: 8px var(--8, 8px);
    align-self: stretch;
    flex-wrap: wrap;
}

.view-edit-invoice-modal.editing .supplier-details,
.view-edit-invoice-modal.editing .invoice-dates,
.view-edit-invoice-modal.editing .edit-invoice-section {
    border-radius: 8px;
    margin-bottom: 16px;
}

.view-edit-invoice-modal.editing .supplier-details .invoice-info,
.view-edit-invoice-modal.editing .invoice-dates .invoice-info,
.view-edit-invoice-modal.editing .edit-invoice-section .invoice-info {
    margin-bottom: 12px!important;
}


.view-edit-invoice-modal.editing .items-table {
    border: 1px solid var(--black-20, rgba(28, 28, 28, 0.20));
    border-radius: 8px;
    overflow: hidden;
}

.view-edit-invoice-modal.editing .items-table th {
    font-weight: 600;
    font-size: 12px;
}

.view-edit-invoice-modal.editing .items-table td {
    padding: 12px 8px;
}

.view-edit-invoice-modal.editing .add-item-button,
.view-edit-invoice-modal.editing .items-table .remove-button {
    background-color: #009A6B;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
    /*  */
}

/* Specific styling for the Invoice Number to push it to the bottom */
.edit-invoice-section .invoice-info:last-child {
  margin-top: auto; /* This will push the invoice number to the bottom */
  margin-bottom: auto;
}

.view-edit-invoice-modal.editing .add-item-button:hover,
.view-edit-invoice-modal.editing .items-table .remove-button:hover {
    background-color: #007a55;
}

.view-edit-invoice-modal.editing .items-table .remove-button {
    background-color: #ff4d4f;
}

.view-edit-invoice-modal.editing .items-table .remove-button:hover {
    background-color: #ff7875;
}

.view-edit-invoice-modal.editing .invoice-totals {
    background-color: #F9F9F9;
    border-radius: 8px;
    padding: 16px;
    margin-top: 16px;
}

.view-edit-invoice-modal.editing .invoice-total-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
}

.view-edit-invoice-modal.editing .invoice-total-row .total-label {
    font-weight: 600;
}

.view-edit-invoice-modal.editing .invoice-footer .save-button,
.view-edit-invoice-modal.editing .invoice-footer .cancel-button {
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    padding: var(--12, 12px) var(--20, 20px);
    justify-content: center;
    align-items: center;
    gap: var(--8, 8px);
    border-radius: var(--12, 12px);
  }

.view-edit-invoice-modal.editing .invoice-footer .save-button:disabled,
.view-edit-invoice-modal.editing .invoice-footer .cancel-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.view-edit-invoice-modal.editing .invoice-footer .save-button {
    background-color: #009A6B;
    color: white;
    border: none;

}

.view-edit-invoice-modal.editing .invoice-footer .save-button:hover {
    background-color: #007a55;
}

.view-edit-invoice-modal.editing .invoice-footer .cancel-button {
  background: var(--black-5, rgba(28, 28, 28, 0.05));
  color: #1C1C1C;
  border: none;

}

.view-edit-invoice-modal.editing .invoice-footer .cancel-button:hover {
    background-color: #f0f0f0;
}

.view-edit-invoice-modal.editing .invoice-items-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px; /* Adjust as needed */
}

.view-edit-invoice-modal.editing .invoice-items-title {
  margin: 0; /* Remove any default margins */
}

.view-edit-invoice-modal.editing .add-item-button {
  /* Reset button styles */
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;

  /* Apply text styles */
  color: var(--Primary-Brand, #009A6B);
  font-feature-settings: 'ss01' on, 'cv01' on, 'cv11' on;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;

  /* Add underline on hover */
  text-decoration: none;
  transition: text-decoration 0.3s;

  /* Add plus sign before text */
  display: flex;
  align-items: center;
}

.view-edit-invoice-modal.editing .add-item-button:hover {
  /* text-decoration: underline; */
  background-color: #1C1C1C0D;
}

.view-edit-invoice-modal.editing .remove-item-button {
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  color: #ff4d4f; 
  font-size: 12px; /* Adjust based on your icon size */
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s;
  /* make it red */
}

.view-edit-invoice-modal.editing .remove-item-button .remove-icon {
  color: #ff4d4f; /* Red color for deletion */
  font-size: 16px; /* Adjust based on your preferred size */
}

.view-edit-invoice-modal.editing .remove-item-button:hover {
  opacity: 0.7;
}

/* Hide text, show only icon */
.view-edit-invoice-modal.editing .remove-item-button span {
  display: none;
  color: #ff4d4f; /* Red color for deletion */
}

/* Assuming you're using a font icon or SVG */
.view-edit-invoice-modal.editing .remove-item-button::before {
  content: '\1F5D1'; /* Unicode for trash can icon, replace with your preferred icon */
  font-family: 'Your Icon Font', sans-serif; /* Replace with your icon font if using one */
}



/* Mobile-friendly styles */
@media screen and (max-width: 768px) {
  .view-edit-invoice-modal {
    width: 100%;
    height: 100%;
    padding: 16px;
  }

  
  .invoice-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* gap: 16px; */
    /* gap: 16px; */
  }

  .invoice-header-left {
    width: 100%;

  }

  .invoice-header-right {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .invoice-header-right .prev-button{
    margin-top: 0;
    margin-bottom: 0;
  }
  .invoice-header-right .next-button{
    margin-top: 0;
    margin-bottom: 0;
  }

  .invoice-header-left h2 {
    font-size: 20px;
    line-height: 28px;
  }

  .supplier-name {
    font-size: 14px;
  }

  .invoice-details-section {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 0; /* Add space for the edit button */
  }

  .supplier-details, .spacer, .issue-date, .invoice-actions {
    grid-column: 1;
  }

  .supplier-details-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 16px;
  }

  .supplier-details {
    order: 1;
    margin-bottom: 16px;
  }
  
  .supplier-details  .entity-name-section {
    margin-bottom: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  .supplier-details .entity-name-section {
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
    max-width: 85%;
    /* font-size: 14px; */
  }
  
  .invoice-actions,
  .invoice-number {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .invoice-number .invoice-info {
    margin-bottom: 0;
    flex-grow: 1;
  }

  .invoice-actions {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
  }

  /* .edit-button {
    position: relative;
    width: 100%;
    margin-bottom: 16px;
    margin-top: 166px;
    margin-right: 16px;
  } */

  .issue-date {
    order:unset;
  }

  .invoice-items {
    /* padding: 12px; */
    order: 3;
  }


  .invoice-number {
    order: unset;
  }

  
  .invoice-items-header-content {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .currency-toggle {
    width: 100%;
    justify-content: space-between;
  }

  .items-table {
    font-size: 12px;
  }

  .items-table th, .items-table td {
    padding: 8px 4px;
  }

  .description-column {
    width: 40%;
  }

  .price-column, .quantity-column, .total-column {
    width: 20%;
  }

  .issue-date {
    width: 100%;
    margin-bottom: 8px;
  }

  /* New styles for mobile layout */
  .issue-date-invoice-number-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 16px;
    order: 2;
  }


  .issue-date,
  .invoice-number {
    width: 48%; /* Adjust as needed */
    margin-bottom: 0;
  }

/* Ensure other elements stack properly */
  .spacer {
    display: none; /* Hide the spacer on mobile if it's not needed */
  }

  .invoice-info {
    margin-bottom: 8px;
  }


  .invoice-footer {
    flex-direction: column;
    align-items: stretch;
  }

  .copy-link-button, .download-original-button, .download-pdf-button {
    width: 100%;
    justify-content: center;
  }

  /* Edit mode adjustments */
  .view-edit-invoice-modal.editing .invoice-info input,
  .view-edit-invoice-modal.editing .items-table input {
    padding: 8px 6px;
    font-size: 12px;
  }

  .view-edit-invoice-modal.editing .add-item-button,
  .view-edit-invoice-modal.editing .items-table .remove-button {
    padding: 6px 12px;
    font-size: 12px;
  }

  .view-edit-invoice-modal.editing .invoice-footer .save-button,
  .view-edit-invoice-modal.editing .invoice-footer .cancel-button {
    padding: 10px 16px;
    font-size: 14px;
  }

  .mobile-nav-buttons {
    display: flex;
    gap: 8px;
  }

  .mobile-nav-buttons button {
    padding: 8px;
    background: var(--black-5, rgba(28, 28, 28, 0.05));
    border: none;
    border-radius: 4px;
  }

  .mobile-price {
    font-size: 12px;
    color: var(--black-60, rgba(28, 28, 28, 0.60));
    margin-top: 4px;
  }

  .items-table .description-column {
    width: 50%;
  }

  .items-table .quantity-column,
  .items-table .total-column {
    width: 25%;
  }




}
