.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .settings-page .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 300px;
    max-width: 80%;
  }
  
  .modal-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .modal-actions button {
    margin-left: 10px;
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .btn-cancel {
    background-color: #f0f0f0;
  }
  
  .btn-confirm {
    background-color: #007bff;
    color: white;
  }
