.metrics-card {
  display: flex;
  flex-direction: column;
  background-color: #f9fafc;
  border-radius: 8px;
  padding: 16px;
  box-sizing: border-box;
  width: 100%;
  min-width: 250px;
  text-align: left;
  flex-grow: 1;
  justify-content: space-between;
}

.card-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.header-left {
  display: flex;
  align-items: flex-start;
}

.loading-indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #009A6B;
  margin-right: 8px;
  margin-top: 6px;
  animation: pulse 1s infinite ease-in-out;
}

@keyframes pulse {
  0% { opacity: 0.5; }
  50% { opacity: 1; }
  100% { opacity: 0.5; }
}

.title-container {
  display: flex;
  flex-direction: column;
}

.header-right {
  display: flex;
  align-items: center;
  font-size: var(--semibold1-size);
  color: var(--primary-brand);
}

.view-text {
  font-size: 12px;
  color: #009A6B;
  font-weight: bold;
}

.title {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #1c1c1c;
}

.subtitle {
  font-size: 12px;
  line-height: 18px;
  color: rgba(28, 28, 28, 0.4);
}

.card-body {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.value {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  color: #1c1c1c;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.trend {
  display: flex;
  align-items: flex-start;
  gap: 4px;
}

.percentage {
  font-size: 12px;
  line-height: 18px;
}

.up {
  color: #34c759!important;
}

.down {
  color: #EE7C30!important;
}

.trend-icon {
  width: 16px;
  height: 16px;
}
