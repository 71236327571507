
.entities-page {
    /* display: flex;
    flex-direction: column;
    height: calc(100vh - 64px); */
}

.table-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden; 
}

.table-content {
    flex-grow: 1;
    overflow-y: auto; 
}

.table-pagination {
    margin-top: auto; 
}
