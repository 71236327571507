.plan-page {
    padding: 24px;
    max-width: 1076px;
    margin: 0 auto;
  }
  
  .plan-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 24px;
  }
  
  .plan-page h2 {
    color: var(--black-100, #1C1C1C);
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 8px;
  }
  
  .plan-subtitle {
    color: var(--black-60, rgba(28, 28, 28, 0.60));
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  
  .divider {
    height: 1px;
    background-color: var(--black-10, rgba(28, 28, 28, 0.10));
    margin: 24px 0;
  }
  
  .plan-content {
    display: flex;
    gap: 48px;
  }
  
  .current-plan {
    flex: 0 0 260px;
  }
  
  .current-plan h3 {
    color: var(--black-100, #1C1C1C);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 4px;
  }
  
  .current-plan p {
    color: var(--black-60, rgba(28, 28, 28, 0.60));
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  
  .plan-options {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .plan-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--black-20, rgba(28, 28, 28, 0.20));
    border-radius: 12px;
    padding: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .plan-option.selected {
    border-color: var(--Primary-Brand, #009A6B);
    background-color: var(--Primary-Light, #F9FAFC);
  }
  
  .plan-option-content {
    flex: 1;
  }
  
  .plan-icon-name {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  
  .plan-icon {
    display: flex;
    width: 32px;
    height: 32px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: var(--radius-sm, 6px);
    border: 1px solid var(--black-5, rgba(28, 28, 28, 0.05));
    background: var(--white-100, #FFF);
    box-shadow: 0px 0px 0px 1px var(--Colors-Effects-Shadows-shadow-skeumorphic-inner-border, rgba(16, 24, 40, 0.18)) inset, 0px -2px 0px 0px var(--Colors-Effects-Shadows-shadow-skeumorphic-inner, rgba(16, 24, 40, 0.05)) inset, 0px 1px 2px 0px var(--Colors-Effects-Shadows-shadow-xs, rgba(16, 24, 40, 0.05));
    margin-right: 12px;
  }
  
  .plan-icon.selected {
    border-color: var(--Primary-Brand, #009A6B);
    background-color: var(--Primary-Light, #F9FAFC);
  }
  
  .plan-icon-name h3 {
    color: var(--black-100, #1C1C1C);
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-right: 12px;
  }
  
  .limited-tag {
    background-color: var(--Primary-Light, #F9FAFC);
    color: var(--Primary-Brand, #009A6B);
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    padding: 2px 8px;
    border-radius: 16px;
  }
  
  .plan-price {
    color: var(--black-100, #1C1C1C);
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 4px;
  }
  
  .plan-price span {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  
  .plan-description {
    color: var(--black-60, rgba(28, 28, 28, 0.60));
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  
  .plan-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  }
  
  .button-group {
    display: flex;
    gap: 12px;
  }
  
  .btn-cancel, .btn-save {
    padding: 10px 18px;
    border-radius: 8px;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    cursor: pointer;
  }
  
  .btn-cancel {
    background-color: white;
    border: 1px solid var(--black-20, rgba(28, 28, 28, 0.20));
    color: var(--black-100, #1C1C1C);
  }
  
  .btn-save {
    background-color: var(--Primary-Brand, #009A6B);
    border: none;
    color: white;
  }


  @media (max-width: 767px) {
    .plan-page {
      padding: 16px;
    }
  
    /* Header adjustments */
    .plan-header {
      flex-direction: column;
      gap: 16px;
      margin-bottom: 16px;
    }
  
    .plan-header h2 {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 4px;
    }
  
    .plan-subtitle {
      font-size: 13px;
      line-height: 18px;
    }
  
    .button-group {
      width: 100%;
      flex-direction: row;
      gap: 8px;
    }
  
    .btn-cancel, 
    .btn-save {
      flex: 1;
      padding: 12px 16px;
      font-size: 14px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    /* Content layout adjustments */
    .plan-content {
      flex-direction: column;
      gap: 24px;
    }
  
    .current-plan {
      flex: none;
      width: 100%;
    }
  
    .current-plan h3 {
      font-size: 15px;
      margin-bottom: 8px;
    }
  
    .current-plan p {
      font-size: 13px;
    }
  
    /* Plan limits section */
    .plan-limits {
      margin-top: 12px;
    }
  
    .plan-limits h4 {
      font-size: 14px;
      margin-bottom: 8px;
    }
  
    .plan-limits ul {
      padding-left: 16px;
    }
  
    .plan-limits li {
      font-size: 13px;
      line-height: 18px;
      margin-bottom: 4px;
    }
  
    /* Plan options adjustments */
    .plan-option {
      padding: 12px;
      flex-direction: column;
      gap: 12px;
    }
  
    .plan-option-content {
      width: 100%;
    }
  
    .plan-icon-name {
      flex-wrap: wrap;
      gap: 8px;
      margin-bottom: 12px;
    }
  
    .plan-icon {
      width: 28px;
      height: 28px;
      padding: 6px;
    }
  
    .plan-icon-name h3 {
      font-size: 15px;
      line-height: 22px;
    }
  
    .limited-tag {
      font-size: 11px;
      padding: 2px 6px;
      margin-left: 0;
      width: fit-content;
    }
  
    .plan-price {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 8px;
    }
  
    .plan-price span {
      font-size: 13px;
    }
  
    .plan-description {
      font-size: 13px;
      line-height: 18px;
      margin-bottom: 12px;
    }
  
    .plan-checkbox {
      align-self: flex-end;
    }
  
    /* Add space between options */
    .plan-options {
      gap: 12px;
    }
  
    /* Divider adjustment */
    .divider {
      margin: 16px 0;
    }
  }
  
  /* Tablet specific adjustments */
  @media (min-width: 768px) and (max-width: 1023px) {
    .plan-page {
      padding: 20px;
    }
  
    .plan-content {
      gap: 32px;
    }
  
    .current-plan {
      flex: 0 0 220px;
    }
  
    .plan-option {
      padding: 16px;
    }
  
    .plan-icon-name h3 {
      font-size: 15px;
    }
  
    .plan-price {
      font-size: 22px;
    }
  
    .plan-description {
      font-size: 13px;
    }
  }