.dashboard-metrics-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
    margin-bottom: 2rem;
  }
  
  @media (min-width: 768px) {
    .dashboard-metrics-container {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  
  .recharts-cartesian-grid-horizontal line {
    border-radius: 16px;
  }