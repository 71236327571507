.sidebar {
  width: 100%;
  max-width: 300px;
  height: calc(100vh - 64px);
  border-right: 1px solid var(--black-5, rgba(28, 28, 28, 0.05));
  background: #FFF;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: max-width 0.3s ease;
  overflow: hidden;
  position: relative; /* Add this line */
  transition: transform 0.5s ease-in-out;
  overflow-y: auto; /* Add this line to enable vertical scrolling */

}

.sidebar__close {
  display: none;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}


.sidebar.collapsed {
  max-width: 60px;
}

.sidebar__toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  background: transparent;
  border: none;
  transition: transform 0.3s ease;
}

.double-arrow-icon {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  transition: transform 0.3s ease;
}

.sidebar.collapsed .sidebar__toggle {
  right: 50%;
  transform: translateX(50%);
}

.sidebar.collapsed .double-arrow-icon {
  transform: rotate(180deg);
}

.sidebar__toggle-icon {
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease;
}


.sidebar__content {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 32px; /* Add this line to create space for the toggle */
  overflow-y: auto; /* Enable scrolling for the content */

}

.sidebar__section {
  padding: 12px;
}

.sidebar__item-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.sidebar__item {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 12px;
  gap: 12px;
  cursor: pointer;
  border-radius: 8px;
  white-space: nowrap;
  text-decoration: none;
  color: inherit;
}

.sidebar__bottom .sidebar__item {
  flex-grow: 0;
  cursor: pointer;
  margin-bottom: 4px;
}


.sidebar__item--active {
  /* Styles for active link */
  background-color: rgba(249, 250, 252, 1);
  font-weight: bold;
  border-radius: 0px 8px 8px 0px;
}

.sidebar__item--active-expense {
  border-left: 3px solid rgba(238, 124, 48, 0.8);
}

.sidebar__item--active-revenue  {
  border-left: 3px solid rgba(22, 97, 56, 0.8);
}

/* Styles for active expense items */
.sidebar__item--active-expense .sidebar__label {
  color: rgba(238, 124, 48, 1); /* Orange color for expenses */
}

.sidebar__item--active-expense .sidebar__icon {
  color: rgba(238, 124, 48, 1) !important;
}

.sidebar__item--active-expense .sidebar__icon path {
  fill: rgba(238, 124, 48, 1);
}

/* Styles for active revenue items */
.sidebar__item--active-revenue .sidebar__label {
  color: rgba(22, 97, 56, 1); /* Green color for revenue */
}

.sidebar__item--active-revenue .sidebar__icon {
  color: rgba(22, 97, 56, 1) !important;
}

.sidebar__item--active-revenue .sidebar__icon path {
  fill: rgba(22, 97, 56, 1);
}

.sidebar__item span {
  flex-grow: 1;
  color: var(--black-100, #1C1C1C);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.sidebar.collapsed .sidebar__item span {
display: none;
}

.sidebar__item:hover {
  background-color: rgba(249, 250, 252, 1)
}

.sidebar__icon {
  color: inherit;
  min-width: 24px;
}

  
  .sidebar__icon--expenses {
    color: rgba(238, 124, 48, 1) !important; /* Secondary/Orange */
  }
  
  .sidebar__icon--revenue {
    color: rgba(22, 97, 56, 1) !important; /* Primary/DarkGreen */
  }
  
  .sidebar__icon--black {
    color: rgba(28, 28, 28, 1) !important; /* Black/100% */
  }

  .sidebar__add-icon,
.sidebar__arrowdown-icon {
    transition: opacity 0.3s ease;
}
  
.sidebar__add-icon {
  position: absolute;
  right: 12px;
  background: var(--Primary-Brand, #009A6B);
  border: none;
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar__add-icon:hover {
  background-color: #007A54; /* Darker shade of green */
}

/* Optional: Add focus style for accessibility */
.sidebar__add-icon:focus {
  outline: 2px solid #007A54;
  outline-offset: 2px;
}


.sidebar__custom-add-icon {
    width: 16px; /* Adjust the width as needed */
    height: 16px; /* Adjust the height as needed */
    border-radius: 4px; /* Border radius from Figma */
    filter: brightness(0) invert(1); /* This makes the icon white */
}

.sidebar__arrowdown-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    border-radius: 8px;
}

.sidebar__custom-arrowdown-icon {
    width: 20px; /* Adjust the width as needed */
    height: 20px; /* Adjust the height as needed */
    border-radius: 4px; /* Border radius from Figma */
    color: rgba(28, 28, 28, 0.2)
}

.sidebar.collapsed .sidebar__add-icon,
.sidebar.collapsed .sidebar__arrowdown-icon {
    opacity: 0;
    width: 0;
    overflow: hidden;
}
  
.sidebar__header {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0 12px;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: bold;
  color: rgba(28, 28, 28, 0.4);
  border-radius: 8px;
  transition: opacity 0.3s ease;
}
  
.sidebar.collapsed .sidebar__header {
  display: none;
}

.sidebar__bottom {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
  margin-top: auto;
  position: sticky; /* Make the bottom section sticky */
  bottom: 0; /* Stick to the bottom of the sidebar */
  background: #FFF; /* Ensure it has a background */
  z-index: 1; /* Ensure it stays on top of scrolling content */
}

  .sidebar__profile {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 16px;
    border-radius: 8px;
    background: var(--primary-light, #F9FAFC);
    transition: padding 0.3s ease;
    margin-top: 8px;
  }
  
  .sidebar.collapsed .sidebar__profile {
    padding: 16px 0;
    justify-content: center;
}

.sidebar.collapsed .sidebar__section, .sidebar.collapsed .sidebar__bottom {
    padding: 5px;
}

.sidebar__profile-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-right: 16px;
}
  
.sidebar__profile-avatar img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
.sidebar.collapsed .sidebar__profile-avatar {
    margin-right: 0;
}
.sidebar.collapsed .sidebar__profile-avatar img {
    width: 30px;
    height: 30px;
}
  
.sidebar__profile-name {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    color: var(--black-100, #1C1C1C);
    transition: opacity 0.3s ease;
}

.sidebar__profile span {
    flex-grow: 1;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    color: var(--black-100, #1C1C1C);
    padding: 0 12px;
    transition: opacity 0.3s ease, width 0.3s ease;
}
  
.sidebar.collapsed .sidebar__profile span {
  display: none;
}

.sidebar__profile-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 8px;
  color: rgba(28, 28, 28, 0.4);
  transition: opacity 0.3s ease, width 0.3s ease;
}

.sidebar.collapsed .sidebar__profile-dots {
  display: none;
}

  
  .sidebar__profile-dots img {
    width: 100%;
    height: auto;
  }
  
  @media (max-width: 768px) {
    .sidebar {
      max-width: 100%;
      height: auto;
      position: fixed;
      top: 64px;
      left: 0;
      height: calc(100vh - 64px);
      z-index: 1000;
      transform: translateX(-100%);
      overflow-y: auto; /* Ensure scrolling works on mobile */
    }
    .sidebar__toggle {
      display: block;
    }
  }

  
  :root {
    --Primary-Brand: #009A6B; /* Green color for the primary brand */
}


@media (max-width: 768px) {
  .sidebar {
    position: fixed;
    top: 64px;
    left: auto;
    right: 0;
    height: calc(100vh - 64px);
    z-index: 1000;
    transform: translateX(100%);
    overflow-y: auto;
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.1); /* Optional: add shadow on left side */
  }

  .sidebar.open {
    transform: translateX(0);
  }

  .sidebar__close {
    display: block;
    position: absolute;
    top: 16px;
    left: 16px; /* Move to left side since sidebar slides from right */
    right: auto;

  }

  .sidebar__toggle {
    display: none;
  }

  /* Adjust any border radius for right-side sliding */
  .sidebar__item--active {
    border-radius: 8px 0 0 8px; /* Flip border radius */
  }

  /* Adjust the active state borders */
  .sidebar__item--active-expense,
  .sidebar__item--active-revenue {
    border-left: none;
    border-right: 3px solid currentColor;
  }
}
