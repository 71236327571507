.chart-component {
  margin-bottom: 2rem;
  padding: 1rem;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid var(--black-5, rgba(28, 28, 28, 0.05));
  position: relative; /* Added for positioning the overlay */
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}

.chart-component h2 {
  margin: 0;
  color: var(--black-100, #1C1C1C);
  font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

/* Updated styles for filters */
.chart-filters {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
  flex-wrap: wrap;
  flex-grow: 1;
  width: 100%;
}

/* Rest of the CSS remains the same */
.chart-filter-dropdown {
  position: relative;
  /* flex: 1 1 calc(50% - 4px); */
  min-width: 120px;
}

.chart-filter-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px;
  border: 1px solid rgba(28, 28, 28, 0.1);
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  gap: 8px;
  color: #1C1C1C;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  min-width: 120px;
  box-sizing: border-box;
}

/* New styles for date inputs wrapper */
.chart-date-inputs {
  display: flex;
  gap: 8px;
}

/* Styles for date inputs */
.chart-filters input[type="date"] {
  min-width: 120px;
}



.chart-filter-button:hover {
  background: var(--white-80, rgba(255, 255, 255, 0.80));
  border: 1px solid var(--Primary-MediumGreen, #79CD8F);
}

.chart-filter-button:disabled,
.chart-dropdown-item.disabled,
.chart-apply-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.chart-apply-button.loading {
    background-color: #cccccc;
    color: #666666;
}

.chart-dropdown-content {
  position: absolute;
  min-width: 250px;
  max-height: 50vh;
  overflow-y: auto;
  z-index: 20;
  border-radius: 12px;
  border: 1px solid rgba(28, 28, 28, 0.10);
  background: #FFF;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.10);
  backdrop-filter: blur(20px);
  margin-top: 4px;
}

.chart-dropdown-item {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  color: var(--black-100, #1C1C1C);
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  cursor: pointer;
}

.chart-dropdown-item:hover {
  background: var(--black-5, rgba(28, 28, 28, 0.05));
}

.chart-apply-button {
  display: inline-flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--Primary-Brand, #009A6B);
  color: var(--white-100, #FFF);
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  border: none;
  cursor: pointer;
  /* width: 100%; */
  box-sizing: border-box;
}

.chart-apply-button:hover {
  background: var(--Primary-Brand-Hover, #00845A);
}


.no-data-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 10;
  border-radius: 8px;
}

.no-data-overlay::before {
  /* content: '📊'; */
  font-size: 48px;
  margin-bottom: 16px;
}

.no-data-overlay p {
  color: var(--black-100, #1C1C1C);
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  max-width: 80%;
}


.custom-tooltip {
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
}

.custom-tooltip .label {
  font-weight: bold;
  margin-bottom: 5px;
}

.custom-tooltip .total {
  color: #009A6B;
}

.echarts-responsive-container {
  margin-bottom: 0 !important;
}


/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .no-data-overlay::before {
    font-size: 36px;
  }

  .no-data-overlay p {
    font-size: 14px;
    line-height: 20px;
  }

  .chart-component {
    padding: 0.5rem;
    /* margin-bottom: 0.5rem; */
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .chart-component h2 {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0.1rem;

  }

  .chart-header {
    margin-bottom: 0.5rem;
  }

  .chart-filters {
    flex-direction: column;
    align-items: stretch;
    gap: 4px;
    margin-bottom: 0.5rem;
  }

  .chart-filter-dropdown,
  .chart-filter-button,
  .chart-apply-button {
    width: 100%;
  }

  .chart-filter-button {
    font-size: 12px;
    padding: 6px;
  }

  /* New styles for date inputs */
  .chart-filters input[type="date"] {
    flex: 1 1 calc(50% - 4px);
    width: calc(50% - 4px);
    min-width: 0;
  }

  .chart-filters input[type="date"]:nth-of-type(2) {
    margin-left: 8px;
  }

  /* Create a wrapper for date inputs */
  .chart-date-inputs {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .chart-apply-button {
    font-size: 12px;
    padding: 8px 12px;
    margin-top: 4px;
    /* margin-bottom: 4px; */
  }

  /* Adjust the chart size for mobile */
  .recharts-responsive-container {
    /* height: 300px !important; */
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  
  .recharts-wrapper {
    font-size: 8px;
    margin-top: 20px;
    margin-bottom: 0 !important;

  }

  .recharts-legend-wrapper {
    margin-bottom: 0 !important;
    bottom: 0 !important;
  }

  .recharts-surface {
    overflow: visible;
  }

  .recharts-wrapper .recharts-cartesian-grid {
    margin-bottom: 0;
  }


  .recharts-cartesian-axis-tick-value {
    font-size: 8px;
  }

  .recharts-legend-item-text {
    font-size: 8px;
  }

  .custom-tooltip {
    padding: 4px;
    font-size: 10px;
  }
}