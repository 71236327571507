.layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  /* Add this line */
}

.layout__main {
  display: flex;
  flex-grow: 1;
  height: calc(100vh - 64px);
  /* overflow: hidden;  */
  /* This would fix the mobile scroll */
}

.layout__sidebar {
  flex-shrink: 0;
}

.layout__content {
  display: flex;
  /* Ensure it uses flexbox */
  flex-direction: column;
  padding: 24px;
  height: 100%;
  overflow-y: auto;
  /* Enable vertical scrolling */
  width: 100%;
}

/* Add this media query for phone screens */
@media screen and (max-width: 767px) {
  .layout__content {
    margin: 0px;
    padding: 0px;
  }
}




