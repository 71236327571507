html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#root {
  height: 100%;
}

.dashboard {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 8px;
}

.dashboard__main {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.dashboard__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.dashboard__scrollable-content {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
}

.charts-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.chart-component {
  flex: 1;
  /* min-height: 400px; */
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid var(--black-5, rgba(28, 28, 28, 0.05));
}

.chart-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.chart-component h2 {
  margin: 0;
  color: var(--black-100, #1C1C1C);
  font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.chart-filters {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  margin-top: 0.5rem;
}

.header-component,
.metrics-cards-container,
.table-component {
  width: 100%;
  box-sizing: border-box;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: auto;
}

.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media (max-width: 768px) {
  .chart-header {
      flex-direction: column;
      align-items: flex-start;
  }

  .chart-filters {
      width: 100%;
      justify-content: flex-start;
  }
}
