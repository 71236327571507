.invoices-page {
    /* display: flex;
    flex-direction: column;
    height: calc(100vh - 64px);  */
}

.invoices-page__content {
    display: flex;
    flex-direction: column;
    flex: 1; 
    overflow: hidden; /* Prevent scrolling on this container */
    /* center the content horizontallyt */
    align-items: center;
}

.header-component {
    /* Add any specific styles for the header */
}

.metrics-cards-container {
    /* Add any specific styles for the metrics cards */
}

.table-component {
    display: flex;
    flex-direction: column;
    min-height: 0; /* This allows the flex item to shrink below its content size if needed */
    overflow: hidden;
    height: 100%;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%; /* Ensure it takes full width */
    position: absolute; /* Position it absolutely within the parent */
    top: 0;
    left: 0;
}

