.view-edit-entity-modal {
    position: fixed;
    top: 0;
    right: 0;
    width: 690px;
    height: 100vh;
    background-color: var(--white-100, #FFF);
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.1);
    padding: 24px;
    z-index: 1000;
    box-sizing: border-box;
    overflow: hidden;
}

.view-edit-entity-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
}

.entity-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid var(--black-5, rgba(28, 28, 28, 0.05));
    padding-bottom: 16px;
}

.entity-header-left {
    display: flex;
    flex-direction: column;
    width: calc(100% - 150px);
}

.entity-header-left h2 {
    margin: 0;
    display: flex;
    align-items: center;
    gap: 8px;
}

.entity-name {
    color: var(--black-40, rgba(28, 28, 28, 0.40));
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 8px;
}

.entity-header-right {
    display: flex;
    gap: 16px;
    align-items: center;
}

.entity-header-right .close-button {
    background: none;
    border: none;
    font-size: 16px;
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0;
    top: 10px;
    cursor: pointer;
    color: var(--black-40, rgba(28, 28, 28, 0.40));
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
    gap: 16px;
}

.entity-header-right .close-button:hover {
    background-color: rgba(28, 28, 28, 0.1);
}

.entity-header-right .prev-button,
.entity-header-right .next-button {
    display: flex;
    align-items: center;
    gap: 4px;
    background: var(--black-5, rgba(28, 28, 28, 0.05));
    color: rgba(28, 28, 28, 1);
    border-radius: 8px;
    padding: 8px 10px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
    border: none;
    margin-top: 32px;
}

.entity-header-right .prev-button:hover,
.entity-header-right .next-button:hover {
    background-color: rgba(28, 28, 28, 0.1);
}

.entity-header-right .prev-button img,
.entity-header-right .next-button img {
    opacity: 0.6;
}

.entity-body {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-grow: 1; /* Add this to make the body take up available space */

}

.entity-details-edit-section {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    padding: 16px 0px;
    box-sizing: border-box;
    align-items: flex-start;
}


.entity-type-title {
    font-feature-settings: 'ss01' on, 'cv01' on, 'cv11' on;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 0;
    margin-bottom: 0;
}



.entity-details-section {
    flex: 1 1 75%; /* Adjust the width as needed */
    display: flex;
    gap: 16px;
    flex-direction: column;
}

.supplier-details-section .entity-type-title {
    color: var(--black-100, #1C1C1C);
    font-feature-settings: 'ss01' on, 'cv01' on, 'cv11' on;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.supplier-details-section .entity-name-section {
    color: var(--black-100, #1C1C1C);
    font-feature-settings: 'ss01' on, 'cv01' on, 'cv11' on;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.supplier-details-section .entity-category-section {
    color: var(--black-60, rgba(28, 28, 28, 0.60));
    font-feature-settings: 'ss01' on, 'cv01' on, 'cv11' on;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}


.edit-button-section {
    flex: 1 1 25%; /* Adjust the width as needed */
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}

.edit-button,
.cancel-button,
.save-button {
    color: #FFF;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s;
    padding: 8px 16px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
}

.edit-button {    
    background: var(--Primary-Brand, #009A6B);
}

.edit-button:hover {
    background: linear-gradient(0deg, rgba(28, 28, 28, 0.20) 0%, rgba(28, 28, 28, 0.20) 100%), #009A6B;
}

.cancel-button {
    background: var(--black-5, rgba(28, 28, 28, 0.05));
    color: #1C1C1C;
}

.cancel-button:hover {
    background-color: #f0f0f0;
}

.save-button {
    background-color: #009A6B;
}

.save-button:hover {
    background-color: #007a55;
}

.save-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.save-button:disable {
    background-color: #f0f0f0;
}


.entity-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.entity-info label {
    font-weight: bold;
    color: var(--black-60, rgba(28, 28, 28, 0.60));
    font-size: 12px;
}

.entity-info p {
    margin: 0;
    color: var(--black-100, #1C1C1C);
    font-size: 14px;
}

.entity-footer {
    margin-top: auto; /* Push the footer to the bottom */
    /* display: flex; */
    /* justify-content: flex-end; */
    /* gap: var(--8, 8px);     */
    padding: 20px 0px 0px 0px;
    /* align-items: center; */
    /* flex-wrap: wrap; */
    border-top: 1px solid var(--black-5, rgba(28, 28, 28, 0.05));
}

.edit-actions {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}

.edit-button {
    color: #FFF;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 0px;
    align-self: flex-start;
    transition: background 0.3s;
    display: flex;
    padding: var(--4, 4px) var(--8, 8px);
    justify-content: flex-end;
    align-items: flex-start;
    gap: var(--4, 4px);
    border-radius: var(--4, 4px);
    background: var(--Primary-Brand, #009A6B);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}

.edit-button:hover {
    background: linear-gradient(0deg, rgba(28, 28, 28, 0.20) 0%, rgba(28, 28, 28, 0.20) 100%), #009A6B;
}

/* Styles for edit mode */
.view-edit-entity-modal.editing .entity-info input,
.view-edit-entity-modal.editing .entity-info select {
    border: 1px solid var(--black-20, rgba(28, 28, 28, 0.20));
    padding: 12px 8px;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
    border-radius: var(--8, 8px);
    background: var(--white-80, rgba(255, 255, 255, 0.80));
    display: flex;
    min-height: var(--Size-28, 28px);
    align-items: center;
    gap: 8px;
}

.view-edit-entity-modal.editing .entity-footer .save-button,
.view-edit-entity-modal.editing .entity-footer .cancel-button {
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    padding: var(--12, 12px) var(--20, 20px);
    justify-content: center;
    align-items: center;
    gap: var(--8, 8px);
    border-radius: var(--12, 12px);
}

.view-edit-entity-modal.editing .entity-footer .save-button {
    background-color: #009A6B;
    color: white;
    border: none;
}

.view-edit-entity-modal.editing .entity-footer .save-button:hover {
    background-color: #007a55;
}

.view-edit-entity-modal.editing .entity-footer .cancel-button {
    background: var(--black-5, rgba(28, 28, 28, 0.05));
    color: #1C1C1C;
    border: none;
}

.view-edit-entity-modal.editing .entity-footer .cancel-button:hover {
    background-color: #f0f0f0;
}

.entity-header-right .prev-button:disabled,
.entity-header-right .next-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.entity-header-right .prev-button:disabled:hover,
.entity-header-right .next-button:disabled:hover {
    background-color: var(--black-5, rgba(28, 28, 28, 0.05));
}

.entity-metrics-section {
    display: flex;
    gap: 16px; /* Space between the cards */
    flex-wrap: wrap; /* Wrap to the next line if necessary */
    justify-content: flex-start; /* Align items to the start */
  }


  /* Mobile-friendly styles */
@media screen and (max-width: 768px) {
    .view-edit-entity-modal {
      width: 100%;
      height: 100%;
      padding: 16px;
    }
  
    .entity-header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .entity-header-left {
      width: 100%;
    }
  
    .entity-header-right {
      width: 100%;
      justify-content: space-between;
      margin-top: 16px;
    }
  
    .entity-header-right .prev-button,
    .entity-header-right .next-button {
      margin-top: 0;
    }
  
    .entity-header-left h2 {
      font-size: 20px;
      line-height: 28px;
    }
  
    .entity-name {
      font-size: 14px;
    }
  
    .entity-details-edit-section {
      flex-direction: column;
      padding-top: 0;
    }
  
    .supplier-details-section {
      width: 100%;
      margin-bottom: 16px;
    }
  
    .supplier-details-section .entity-name-section {
      font-size: 16px;
      line-height: 22px;
      word-wrap: break-word;
      overflow-wrap: break-word;
      hyphens: auto;
      max-width: 100%;
    }
  
    .edit-button-section {
      width: 100%;
      justify-content: flex-start;
      margin-top: 16px;
    }
  
    .entity-metrics-section {
      flex-direction: column;
    }
  
    .entity-metrics-section > div {
      width: 100%;
    }
  
    .entity-footer {
      padding-top: 16px;
    }
  
    .edit-actions {
      flex-direction: column-reverse;
      gap: 12px;
    }
  
    .edit-actions button {
      width: 100%;
    }
  
    /* Adjustments for edit mode on mobile */
    .view-edit-entity-modal.editing .entity-info input,
    .view-edit-entity-modal.editing .entity-info select {
      padding: 8px 6px;
      font-size: 14px;
    }
  
    .view-edit-entity-modal.editing .entity-footer .save-button,
    .view-edit-entity-modal.editing .entity-footer .cancel-button {
      padding: 12px 16px;
      font-size: 14px;
    }
  }
  