@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kufam:wght@700&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  line-height: normal;
  font-family: 'Inter', sans-serif; /* Default font */
}

:root {
  /* Fonts */
  --font-primary: 'Inter', sans-serif;
  --font-secondary: 'Kufam', sans-serif;

  /* Font Sizes */
  --font-size-small: 12px;
  --font-size-regular: 14px;
  --font-size-medium: 16px;
  --font-size-large: 18px;
  --font-size-xlarge: 24px;
  --font-size-xxlarge: 32px;
  --font-size-xxxlarge: 48px;

  /* Font Weights */
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 600;  
  --font-weight-black: 900;

  /* Colors */
  --color-black: #1c1c1c;
  --color-black-5: rgba(28, 28, 28, 0.05);
  --color-black-10: rgba(28, 28, 28, 0.1);
  --color-black-20: rgba(28, 28, 28, 0.2);
  --color-black-40: rgba(28, 28, 28, 0.4);
  --color-black-60: rgba(28, 28, 28, 0.6);
  --color-black-80: rgba(28, 28, 28, 0.8);
  --color-gainsboro: #e4e7ec;
  --color-gainsboro-80: rgba(228, 231, 236, 0.8);
  --color-light-gray: #f0f0f0;
  --color-light-green: #E6F5EA;
  --color-lighter-gray: #f2f2f2;
  --color-lightest-gray: #f9f9f9;
  --color-primary: #009a6b;
  --color-primary-blue: #0183fb;
  --color-primary-light: #f9fafc;
  --color-primary-light-alt: #f7f9fb;
  --color-secondary-green: #34c759;
  --color-secondary-orange: #ee7c30;
  --color-white: #fff;
  --color-white-5: rgba(255, 255, 255, 0.05);
  --color-white-10: rgba(255, 255, 255, 0.1);
  --color-white-20: rgba(255, 255, 255, 0.2);
  --color-white-40: rgba(255, 255, 255, 0.4);
  --color-white-60: rgba(255, 255, 255, 0.6);
  --color-white-80: rgba(255, 255, 255, 0.8);

  /* Spacing */
  --spacing-xs: 4px;
  --spacing-sm: 8px;
  --spacing-md: 16px;
  --spacing-lg: 20px;
  --spacing-xl: 24px;
  --spacing-xxl: 32px;
  --spacing-xxxl: 40px;
  --spacing-huge: 64px;
  --spacing-massive: 80px;
  --spacing-9xl: 96px;

  /* Gaps */
  --gap-base: 16px;
  --gap-xs: 12px;
  --gap-sm: 8px;
  --gap-xxs: 4px;

  /* Paddings */
  --padding-xs: 4px;
  --padding-sm: 8px;
  --padding-md: 16px;
  --padding-lg: 20px;
  --padding-xl: 24px;

  /* Border Radiuses */
  --border-radius-xs: 4px;
  --border-radius-sm: 8px;
  --border-radius-md: 12px;
  --border-radius-lg: 16px;
  --border-radius-xl: 80px;

  /* Effects */
  --effect-blur: blur(40px);
}
