.settings-page {
    padding: 0 8px 8px 8px;
    overflow-x: hidden;
    max-width: 1200px; /* Add a max-width to the entire settings page */
  }
  
  .settings-title {
    color: var(--black-100, #1C1C1C);
    font-family: Inter, sans-serif;
    font-size: var(--Font-size-display-sm, 30px);
    font-style: normal;
    font-weight: 600;
    line-height: var(--Line-height-display-sm, 38px);
    margin-bottom: 16px;
  }
  
  .tabs-container {
    display: flex;
    border-bottom: none;
    margin-bottom: 32px;
    overflow-x: hidden;
  }
  
  .tab {
    display: flex;
    min-height: 40px;
    padding: var(--spacing-md, 8px) var(--spacing-xl, 16px);
    justify-content: center;
    align-items: center;
    gap: var(--spacing-md, 8px);
    border: none;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: var(--black-100, #1C1C1C);
    font-feature-settings: 'ss01' on, 'cv01' on, 'cv11' on;
    box-shadow: 0px 0px 0px 1px var(--Colors-Effects-Shadows-shadow-skeumorphic-inner-border, rgba(16, 24, 40, 0.18)) inset, 
              0px -2px 0px 0px var(--Colors-Effects-Shadows-shadow-skeumorphic-inner, rgba(16, 24, 40, 0.05)) inset, 
              0px 1px 2px 0px var(--Colors-Effects-Shadows-shadow-xs, rgba(16, 24, 40, 0.05));    
    margin-right: -1px; /* This overlaps the borders */
    cursor: pointer;
  }


  .tab:first-child {
    border-top-left-radius: var(--radius-md, 8px);
    border-bottom-left-radius: var(--radius-md, 8px);

  }
  
  .tab:last-child {
    border-top-right-radius: var(--radius-md, 8px);
    border-bottom-right-radius: var(--radius-md, 8px);
    border-right: 1px solid var(--black-10, rgba(28, 28, 28, 0.10));
  }
  
  .tab-active {
    background: var(--Colors-Background-bg-active, #F9FAFB);
    position: relative;
    z-index: 1;
  }
  
  .tab-inactive {
    background: var(--Colors-Background-bg-primary, #FFF);
  }

  .settings-section {
    margin-bottom: 40px;
  }
  
  .settings-section h2 {
    color: var(--black-100, #1C1C1C);
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 8px;
  }
  
  .section-subtitle {
    color: var(--black-60, rgba(28, 28, 28, 0.60));
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 24px;
    margin-top: 0px;
  }

  .settings-section form {
    margin-top: 32px;
  }
  
  .form-item {
    display: flex;
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--black-10, rgba(28, 28, 28, 0.10));
  }
  
  .form-item-label {
    flex: 0 0 30%; /* Set a fixed width for the label column */
    padding-right: 24px;
  }
  
  .form-item-label label {
    color: var(--black-100, #1C1C1C);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 4px;
    display: block;
  }
  
  .form-item-description {
    color: var(--black-60, rgba(28, 28, 28, 0.60));
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 0px;
  }
  
  .form-item-input {
    flex: 0 0 70%; /* Set a fixed width for the input column */
    max-width: 500px; /* Set a maximum width for the input fields */
  }
  
  .form-item-input input {
    width: 100%;
    padding: 10px 14px;
    border: 1px solid var(--black-20, rgba(28, 28, 28, 0.20));
    border-radius: var(--radius-md, 8px);
    font-family: Inter, sans-serif;
    font-size: 14px;
    line-height: 20px;
  }

  /* Add styles for select arrow */
  .form-item-input select {
    width: 100%;
    padding: 10px 14px;
    border: 1px solid var(--black-20, rgba(28, 28, 28, 0.20));
    border-radius: var(--radius-md, 8px);
    font-family: Inter, sans-serif;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 12px;
    box-sizing: border-box;
    
    /* Remove transition */
    transition: none;
    
    /* Styling for select dropdown arrow */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L9 1' stroke='%231C1C1C' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 14px center;
    background-color: white; /* Ensure background color is set */
    padding-right: 30px; /* Adjust padding to prevent text overlap with arrow */
  }

  .form-item-input input,
  .form-item-input select {
    width: 100%;
    padding: 10px 14px;
    border: 1px solid var(--black-20, rgba(28, 28, 28, 0.20));
    border-radius: var(--radius-md, 8px);
    font-family: Inter, sans-serif;
    font-size: 14px;
    line-height: 20px;
    transition: all 0.3s ease;
    margin-bottom: 12px;
    box-sizing: border-box; /* Add this to include padding in the width calculation */
    transition: none;

  }

  .form-item-input input:focus,
  .form-item-input select:focus {
    outline: none;
    border-radius: var(--8, 8px);
    border: 1px solid var(--Primary-LightGreen, #BEE7C6);
    background: var(--white-80, rgba(255, 255, 255, 0.80));
    box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.05);
  }

  
  .form-item-input input:last-child,
  .form-item-input select:last-child {
    margin-bottom: 0;
  }

  /* Style for the file input (logo upload) */
  .form-item-input input[type="file"] {
    border: none;
    padding: 0;
  }

/* Add this if you want to style the file input button */
  .form-item-input input[type="file"]::-webkit-file-upload-button {
    padding: 10px 14px;
    border: 1px solid var(--black-20, rgba(28, 28, 28, 0.20));
    border-radius: var(--radius-md, 8px);
    background-color: white;
    font-family: Inter, sans-serif;
    font-size: 14px;
    cursor: pointer;
  }


  .required {
    color: var(--Primary-Brand, #009A6B);
    font-feature-settings: 'ss01' on, 'cv01' on, 'cv11' on;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-left: 2px;
  }

  .disabled-input {
    background-color: #f0f0f0;
    color: #666;
    cursor: not-allowed;
  }
  
  .button-group {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 24px;
  }
  
  .btn-cancel {
    display: flex;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: var(--radius-md, 8px);
    border: 1px solid var(--black-20, rgba(28, 28, 28, 0.20));
    background: white;
    color: var(--black-100, #1C1C1C);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    cursor: pointer;
  }
  
  .btn-save {
    display: flex;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: var(--radius-md, 8px);
    background: var(--Primary-Brand, #009A6B);
    color: white;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    border: none;
    cursor: pointer;
  }

  

  /* Team section styles */

  .invite-team-members {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 24px;
    margin-bottom: 40px;
    padding-bottom: 32px;
    position: relative;
  }
  
  /* .invite-team-members::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: var(--black-10, rgba(28, 28, 28, 0.10));
  } */

  .invite-column {
    display: flex;
    flex-direction: column;

  }


  .invite-header {
    flex: 1 1 100%; /* Full width on small screens, flexible on larger screens */
  }
  
  .invite-emails,
  .invite-roles {
    flex: 1 1 auto; /* Allow these to grow and shrink as needed */
  }

  .invite-roles {
    min-width: 150px; /* Minimum width instead of fixed max-width */
  }
 

  .invite-roles select,
  .send-invites {
    width: 100%; /* Full width */
    max-width: none; /* Remove max-width constraint */
  }

  
  .invite-team-members h3 {
    color: var(--black-100, #1C1C1C);
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 8px;
  }
  
  .invite-team-members p {
    color: var(--black-60, rgba(28, 28, 28, 0.60));
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  
  .invite-emails input,
  .invite-roles select {
    width: 100%;
    height: 40px; /* Set a fixed height for both inputs and selects */
    padding: 8px 12px; /* Adjust padding to fit the fixed height */
    border: 1px solid var(--black-20, rgba(28, 28, 28, 0.20));
    border-radius: var(--radius-md, 8px);
    font-family: Inter, sans-serif;
    font-size: 14px;
    line-height: 22px; /* Adjust line-height to center text vertically */
    margin-bottom: 12px;
    box-sizing: border-box; /* Ensure padding is included in the element's total width and height */
  }
  
  /* Ensure consistent styling for select dropdowns across browsers */
  .invite-roles select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L9 1' stroke='%231C1C1C' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 12px center;
    padding-right: 30px; /* Make room for the custom dropdown arrow */
    max-width: 150px;
  }
  
  /* Remove default arrow in IE */
  .invite-roles select::-ms-expand {
    display: none;
  }
  
  /* Adjust the layout of the invite columns */

  


  
  /* Align the "Add another" and "Send invites" buttons */
  .add-another,
  .send-invites {
    margin-top: 4px; /* Adjust this value to align with the last input field */
  }

  .add-another {
    color: var(--Primary-Brand, #009A6B);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-top: auto;
    align-self: flex-start;
  }
  
  .send-invites {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: var(--radius-md, 8px);
    background: var(--Primary-Brand, #009A6B);
    color: white;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    border: none;
    cursor: pointer;
    margin-top: auto;
    align-self: flex-start;
    max-width: 150px;
    transition: all 0.3s ease;
  }

  .send-invites.sending {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }
  
  .send-invites:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  .invite-team-members .error-message {
    color: #d32f2f;
    font-size: 0.875rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  
  .pending-invitation {
    background-color: #f8f8f8;
  }
  
  .pending-invitation td {
    color: #666;
  }

  .status-badge {
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 0.85em;
    font-weight: 500;
  }
  
  .status-badge.active {
    background-color: #e6f4ea;
    color: #1e7e34;
  }
  
  .status-badge.pending {
    background-color: #fff3e0;
    color: #f57c00;
  }
  

.team-content {
  position: relative;
  padding: 32px 0;
}

.team-content::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  background-color: var(--black-10, rgba(28, 28, 28, 0.10));
}

.team-content::before {
  top: 0;
}

.team-content::after {
  bottom: 0;
}





.invite-team-members h3,
.team-members h3 {
  color: var(--black-100, #1C1C1C);
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
}



.invite-team-members p,
.team-members p {
  color: var(--black-60, rgba(28, 28, 28, 0.60));
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 16px;
}

.invitee-row {
  display: flex;
  gap: 12px;
  margin-bottom: 12px;
}

.invitee-row input,
.invitee-row select {
  flex: 1;
  padding: 10px 14px;
  border: 1px solid var(--black-20, rgba(28, 28, 28, 0.20));
  border-radius: var(--radius-md, 8px);
  font-family: Inter, sans-serif;
  font-size: 14px;
  line-height: 20px;
}

.invitee-row select {
  width: 30%;
  max-width: 150px;}


.add-another {
  color: var(--Primary-Brand, #009A6B);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-bottom: 16px;
}

.send-invites {
  display: flex;
  /* padding: 10px 18px; */
  justify-content: center;
  align-items: center;
  /* gap: 8px; */
  border-radius: var(--radius-md, 8px);
  background: var(--Primary-Brand, #009A6B);
  color: white;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  max-width: none;
  padding: 10px 18px;
}

.team-members {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 40px;
  padding-bottom: 32px;
  position: relative;
}

/* .team-members::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: var(--black-10, rgba(28, 28, 28, 0.10));
} */


.team-column {
  display: flex;
  flex-direction: column;
}

.team-header {
  flex: 0 0 30%;
}

/* .team-table {
  flex: 0 0 70%;
} */

.team-members h3 {
  color: var(--black-100, #1C1C1C);
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
}

.team-members p {
  color: var(--black-60, rgba(28, 28, 28, 0.60));
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}


.team-members th,
.team-members td {
  padding: 12px;
  text-align: left;
}

.team-members tr:not(:last-child) th,
.team-members tr:not(:last-child) td {
  border-bottom: 1px solid var(--black-10, rgba(28, 28, 28, 0.10));
}



.team-members th {
  color: var(--black-80, rgba(28, 28, 28, 0.80));
  font-feature-settings: 'ss01' on, 'cv01' on, 'cv11' on;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
}

.team-members td {
  font-weight: 400;
  color: var(--black-100, #1C1C1C);
}

.team-members .email {
  color: var(--black-60, rgba(28, 28, 28, 0.60));
  font-size: 12px;
}

.team-members .delete,
.team-members .edit {
  padding: 6px 12px;
  border-radius: var(--radius-md, 8px);
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  cursor: pointer;
  margin-right: 8px;
}

.team-members .delete {
  color: var(--Danger-Red, #D92D20);
  background: none;
  border: 1px solid var(--Danger-Red, #D92D20);
}

.team-members .edit {
  color: var(--black-100, #1C1C1C);
  background: none;
  border: 1px solid var(--black-20, rgba(28, 28, 28, 0.20));
}

.team-members .cancel {
  color: var(--Danger-Red, #D92D20);
  background: none;
  border: none;
  cursor: pointer;
}

.team-members .cancel:hover {
  text-decoration: underline;
}

/* Style for the role dropdown when editing */
.team-members select {
  width: 100%;
  padding: 6px 30px 6px 12px; /* Increased right padding for the arrow */
  border: 1px solid var(--black-20, rgba(28, 28, 28, 0.20));
  border-radius: var(--radius-md, 8px);
  font-family: Inter, sans-serif;
  font-size: 14px;
  line-height: 20px;
  background-color: white;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L9 1' stroke='%231C1C1C' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
}

/* Style for the Save button when editing */
.team-members .save {
  padding: 6px 12px;
  border-radius: var(--radius-md, 8px);
  background: var(--Primary-Brand, #009A6B);
  color: white;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  border: none;
  cursor: pointer;
}

/* Style for the Save button when it's in 'Saving...' state */
.team-members .save:disabled {
  background: var(--black-20, rgba(28, 28, 28, 0.20));
  color: var(--black-60, rgba(28, 28, 28, 0.60));
  cursor: not-allowed;
}

/* Adjust the layout of the editing row */
.team-members tr.editing td {
  padding-top: 6px;
  padding-bottom: 6px;
}

/* Add some space between the dropdown and the Save button */
.team-members tr.editing td > div {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Ensure the dropdown doesn't take up too much space */
.team-members tr.editing select {
  max-width: 120px;
}



/* Ensure consistent styling between invite and team sections */
.invite-team-members,
.team-members {
  margin-bottom: 40px;
}

.team-content {
  position: relative;
  padding: 32px 0;
}

.team-column.team-table {
  width: 100%;
  border: 1px solid var(--black-10, rgba(28, 28, 28, 0.10));
  border-radius: var(--radius-xl, 12px);
  box-shadow: 0px 1px 2px 0px var(--Colors-Effects-Shadows-shadow-xs, rgba(16, 24, 40, 0.05));
  /* overflow: hidden; */
}

.team-members table {
  border-collapse: separate;
  border-spacing: 0;
}

.team-members th,
.team-members td {
  padding: 12px 16px;
  text-align: left;
}

.team-members th:last-child,
.team-members td:last-child {
  padding-right: 24px;
}


.team-members thead {
  background: var(--Primary-Light, #F9FAFC);
}

.team-members thead tr {
  height: 44px;
}


.team-members td > div > div:first-child {
  color: var(--black-100, #1C1C1C);
  font-feature-settings: 'ss01' on, 'cv01' on, 'cv11' on;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}


.team-members .email {
  color: var(--black-60, rgba(28, 28, 28, 0.60));
  font-feature-settings: 'ss01' on, 'cv01' on, 'cv11' on;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}
.team-members td:nth-child(2) {
  color: var(--black-100, #1C1C1C);
  font-feature-settings: 'ss01' on, 'cv01' on, 'cv11' on;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}




.team-members .delete,
.team-members .edit {
  padding: 6px 12px;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  background: none;
  border: none;
}

.team-members .delete {
  color: var(--Danger-Red, #D92D20);
}

.team-members .edit {
  color: var(--Primary-Brand, #009A6B);
}

/* Ensure the table doesn't overflow its container */
.team-column.team-table {
  overflow-x: auto;
}

/* Add some space between the buttons */
.team-members .delete {
  margin-right: 8px;
}



/* Media query for larger screens */
@media (min-width: 768px) {
  .invite-team-members {
    flex-wrap: nowrap; /* Prevent wrapping on larger screens */
  }

  .invite-header {
    flex: 0 0 30%; /* Fixed width for the header on larger screens */
  }

  .invite-emails {
    flex: 1 1 auto; /* Allow emails to take up remaining space */
  }

  .invite-roles {
    flex: 0 0 150px; /* Fixed width for roles on larger screens */
  }
}

/* Media query for smaller screens */
@media (max-width: 767px) {
  .invite-team-members {
    flex-direction: column;
  }

  .invite-column {
    width: 100%;
  }
}

/* Mobile styles for Settings Page */
@media (max-width: 767px) {
  .settings-page {
    padding: 0 16px;
    max-width: 100%;
  }

  .settings-title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 12px;
  }

  /* Tabs adjustments */
  .tabs-container {
    overflow-x: auto;
    margin-bottom: 24px;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    padding-bottom: 4px; /* Space for shadow */
  }

  .tabs-container::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }

  .tab {
    padding: 8px 12px;
    font-size: 13px;
    white-space: nowrap;
    min-width: auto;
  }

  /* Form layout adjustments */
  .form-item {
    flex-direction: column;
    margin-bottom: 16px;
    padding-bottom: 16px;
  }

  .form-item-label {
    flex: 0 0 100%;
    padding-right: 0;
    margin-bottom: 8px;
  }

  .form-item-input {
    flex: 0 0 100%;
    max-width: 100%;
  }

  /* Team section adjustments */
  .team-content {
    padding: 16px 0;
  }

  .invite-team-members {
    flex-direction: column;
    gap: 16px;
    margin-bottom: 24px;
    padding-bottom: 24px;
  }

  .invite-column {
    width: 100%;
  }

  .invite-header h3 {
    font-size: 15px;
    line-height: 22px;
  }

  .invite-header p {
    font-size: 13px;
    line-height: 18px;
  }

  .invite-roles {
    min-width: 100%;
  }

  .invite-roles select {
    max-width: 100%;
  }

  .send-invites {
    max-width: 100%;
  }

  /* Team members table adjustments */
  .team-members {
    flex-direction: column;
    gap: 16px;
    margin-bottom: 24px;
    padding-bottom: 24px;
  }

  .team-column.team-header {
    flex: 0 0 100%;
  }

  .team-column.team-table {
    overflow-x: auto;
    border-radius: 8px;
  }

  .team-members table {
    min-width: 600px; /* Minimum width to ensure all columns are visible */
  }

  .team-members th,
  .team-members td {
    padding: 8px 12px;
    font-size: 13px;
  }

  .team-members td > div > div:first-child {
    font-size: 13px;
    line-height: 18px;
  }

  .team-members .email {
    font-size: 11px;
    line-height: 16px;
  }

  /* Action buttons in tables */
  .team-members .delete,
  .team-members .edit,
  .team-members .cancel {
    padding: 4px 8px;
    font-size: 12px;
  }

  /* Organization section adjustments */
  .form-item-input input[type="file"] {
    width: 100%;
  }

  .company-logo-preview {
    max-width: 100%;
    height: auto;
  }

  /* Button group adjustments */
  .button-group {
    flex-direction: column-reverse;
    gap: 8px;
    margin-top: 16px;
  }

  .btn-cancel,
  .btn-save {
    width: 100%;
    padding: 12px 16px;
  }

  /* Status badges */
  .status-badge {
    padding: 2px 6px;
    font-size: 11px;
  }

  /* Select dropdowns in mobile */
  .invite-roles select,
  .team-members select {
    padding-right: 24px;
    background-position: right 8px center;
  }

  /* Error messages */
  .error-message {
    font-size: 12px;
    margin: 4px 0;
  }
}

/* Additional tablet-specific adjustments */
@media (min-width: 768px) and (max-width: 1023px) {
  .settings-page {
    padding: 0 24px;
  }

  .form-item-label {
    flex: 0 0 35%;
  }

  .form-item-input {
    flex: 0 0 65%;
  }

  .team-members table {
    min-width: 100%;
  }
}