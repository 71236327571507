/* Prefix all classes with 'fp-' for 'forgot password' to avoid conflicts */
.fp-container {
    display: flex;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    padding: 24px;
    background-color: var(--white-100, #FFF);
  }
  
  .fp-form {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  
  .fp-form .suma-logo {
    height: 32px;
    width: auto;
    margin-bottom: 8px;
  }
  
  .fp-header {
    text-align: left;
  }
  
  .fp-header h1 {
    color: var(--black-100, #1C1C1C);
    font-family: Inter, sans-serif;
    font-size: 30px;
    font-weight: 600;
    line-height: 38px;
    margin-bottom: 8px;
  }
  
  .fp-header p {
    color: var(--black-60, rgba(28, 28, 28, 0.60));
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
  }
  
  .fp-form .form-group {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-bottom: 16px;
  }
  
  .fp-form .form-group label {
    color: var(--black-100, #1C1C1C);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
  
  .fp-form .form-group input {
    padding: 10px 14px;
    border-radius: 8px;
    border: 1px solid var(--black-20, rgba(28, 28, 28, 0.20));
    background: var(--white-100, #FFF);
    font-family: Inter, sans-serif;
    font-size: 14px;
  }
  
  .fp-form .form-group input:focus {
    outline: none;
    border-color: var(--Primary-Brand, #009A6B);
    box-shadow: 0 0 0 4px rgba(0, 154, 107, 0.1);
  }
  
  .fp-submit-btn {
    width: 100%;
    padding: 10px 18px;
    border-radius: 8px;
    background: var(--Primary-Brand, #009A6B);
    color: white;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .fp-submit-btn:hover {
    background-color: var(--Primary-Dark, #007A54);
  }
  
  .fp-submit-btn:disabled {
    background-color: var(--black-20, rgba(28, 28, 28, 0.20));
    cursor: not-allowed;
  }
  
  .fp-message {
    padding: 12px 16px;
    border-radius: 8px;
    font-family: Inter, sans-serif;
    font-size: 14px;
    line-height: 20px;
    margin-top: 16px;
  }
  
  .fp-message.success {
    background-color: var(--success-light, #E6F4EA);
    color: var(--success, #1E7E34);
  }
  
  .fp-message.error {
    background-color: var(--error-light, #FDECEA);
    color: var(--error, #D32F2F);
  }
  
  .fp-back-to-login {
    text-align: center;
    margin-top: 16px;
  }
  
  .fp-back-to-login a {
    color: var(--Primary-Brand, #009A6B);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
  }
  
  .fp-back-to-login a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .fp-container {
      padding: 16px;
    }
  
    .fp-header h1 {
      font-size: 24px;
      line-height: 32px;
    }
  }
  