.csv-download-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 4px var(--8, 8px);
    background: var(--Primary-Brand, #009A6B);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease; 
}

.csv-download-button:hover:not(:disabled) {
    background: rgba(0, 154, 107, 1);
}

.csv-download-button:disabled {
    opacity: 1;
    cursor: not-allowed;
    background: var(--black-5, rgba(28, 28, 28, 0.05));
    color: var(--black-40, rgba(28, 28, 28, 0.40));
}

.csv-download-button img {
    width: 20px;
    height: 20px;
}

.csv-download-button.action-csv-button .csv-icon.action-icon {
    filter: brightness(0) saturate(100%) invert(20%) sepia(10%) saturate(2076%) hue-rotate(86deg) brightness(90%) contrast(86%);
}


.csv-download-button span {
    white-space: nowrap;
    color: var(--white-100, #FFF);
    text-align: center;
    font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}

/* Add these new classes for different button styles */
.csv-download-button.normal-csv-button {
    margin-left: 8px; /* Add some space between the column selector and CSV button */
    white-space: nowrap;
    color: var(--white-100, #FFF);
    text-align: center;
    font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}

.csv-download-button.action-csv-button {
    height: var(--Size-28, 28px);
    padding: var(--4, 4px) var(--12, 12px);
    justify-content: center;
    align-items: center;
    gap: var(--4, 4px);
    border-radius: var(--8, 8px);
    background: var(--black-10, rgba(28, 28, 28, 0.10));
    color: var(--black-80, rgba(28, 28, 28, 0.80));
    text-align: center;
    font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;

    /* 12 Semibold */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 150% */
    transition: background-color 0.3s ease  ;
}

/* icon inside the action-csv-button */
.csv-download-button.action-csv-button img {
    width: 16px;
    height: 16px;
    color: rgba(28, 28, 28, 0.6);
}

.csv-download-button.action-csv-button:hover:not(:disabled) {
    border-radius: var(--12, 12px);
    background: var(--black-10, rgba(28, 28, 28, 0.40));
}
