.signin-container {
  display: flex;
  min-height: 100vh;
  overflow: hidden;
}

.signin-form {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 2rem;
  min-width: 358px;
  max-width: 480px;
}

.suma-logo {
  width: 140px;
  margin-bottom: 2rem;
}

.signin-header {
  width: 100%;
  min-width: 358px;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  margin-bottom: 1rem;
  margin-top: 4rem;
}

.signin-header h1 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.signin-header p {
  color: var(--black-60, rgba(28, 28, 28, 0.60));
  font-size: 1rem;
  margin-bottom: 1rem;
}

.form-group,
.form-group input,
.submit-btn,
.google-btn {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.form-group input,
.submit-btn,
.google-btn {
  box-sizing: border-box;
  padding: 10px;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group form {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.form-group input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.forgot-password-link {
  text-align: right;
  color: var(--Primary-Brand, #009A6B);
  text-decoration: none;
  font-weight: 600;
}

.submit-btn {
  width: 100%;
  display: flex;
  padding: 10px var(--spacing-xl, 16px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-sm, 6px);
  align-self: stretch;
  border-radius: var(--radius-md, 8px);
  background: var(--Primary-Brand, #009A6B);
  color: var(--Component-colors-Components-Buttons-Primary-button-primary-fg, #FFF);
  margin-bottom: 16px;
  border: none;
  cursor: pointer;
  font-family: var(--Font-family-font-family-body, Inter);
  font-size: var(--Font-size-text-md, 16px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--Line-height-text-md, 24px);
}

.submit-btn:hover {
  background: #007f5f;
}

.submit-btn:disabled {
  background: #cccccc;
  cursor: not-allowed;
}

.google-btn {
  width: 100%;
  padding: 0.75rem;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: var(--spacing-lg, 12px);
  color: var(--black-100, #1C1C1C);
  font-family: var(--Font-family-font-family-body, Inter);
  font-size: var(--Font-size-text-md, 16px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--Line-height-text-md, 24px);
}

.google-btn:hover {
  background: var(--Primary-Light, #F9FAFC);
  border: 1px solid #e0e0e0;
}

.google-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.google-btn img {
  margin-right: 0.5rem;
}

.signup-link {
  text-align: center;
  margin-top: 1rem;
  color: var(--black-60, rgba(28, 28, 28, 0.60));
  font-family: var(--Font-family-font-family-body, Inter);
  font-size: var(--Font-size-text-sm, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--Line-height-text-sm, 20px);
}

.signup-link a {
  color: #009A6B;
  text-decoration: none;
  font-weight: 600;
}

.error-message {
  color: red;
  margin-top: 10px;
  text-align: center;
}

.signin-info {
  flex: 1;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  max-width: 100%;
  padding: var(--spacing-9xl, 96px) var(--spacing-7xl, 64px) var(--spacing-none, 0px) var(--spacing-8xl, 80px);
  align-items: flex-start;
  gap: var(--spacing-9xl, 96px);
  flex: 1 0 0;
  align-self: stretch;
  background: var(--Colors-Background-bg-tertiary, #F2F4F7);
}


.signup-container {
  display: flex;
  min-height: 100vh;
  overflow: hidden;
}

.signup-form {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  flex: 1;
  padding: 2rem;
  min-width: 358px;
  max-width: 480px;
}

.suma-logo {
  width: 100px;
  margin-bottom: 2rem;
}

.signup-header {
  width: 100%;
  min-width: 358px;
  max-width: 480px;
  /* Match this to the max-width of other elements */
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  margin-bottom: 1rem;
}

.signup-header h1 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  width: 100%;
  min-width: 358px;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
}

.signup-header p {
  color: var(--black-60, rgba(28, 28, 28, 0.60));
  font-size: 1rem;
  margin-bottom: 1rem;
}


.user-type-selector {
  display: flex;
  margin-bottom: 24px;
  display: flex;
  padding: var(--spacing-xs, 4px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-xs, 4px);
  /* align-self: stretch; */
  border-radius: var(--radius-lg, 10px);
  border: 1px solid var(--black-5, rgba(28, 28, 28, 0.05));
  background: var(--Primary-Light, #F9FAFC);
  min-width: 358px;
  max-width: 480px;
  /* Match this to the max-width of other elements */
  width: 100%;
  /* Ensure it takes full width up to max-width */
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  /* Add this line */

}

.user-type-selector button {
  flex: 1;
  padding: 0.5rem;
  background: none;
  cursor: pointer;
  padding: 8px;
  display: flex;
  height: 36px;
  padding: var(--spacing-md, 8px) var(--spacing-lg, 12px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-md, 8px);
  flex: 1 0 0;
  border-radius: var(--radius-sm, 6px);
  color: var(--black-100, #1C1C1C);
  /* Text sm/Semibold */
  font-family: var(--Font-family-font-family-body, Inter);
  font-size: var(--Font-size-text-sm, 14px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--Line-height-text-sm, 20px);
  /* 142.857% */
  white-space: nowrap;
  /* Prevent text from wrapping */
  overflow: hidden;
  text-overflow: ellipsis;
  /* Add ellipsis for overflowing text */
  border: none;
}

.user-type-selector button.active {
  background: var(--Colors-Background-bg-primary_alt, #FFF);
  border: 1px solid #e0e0e0;
  /* Shadows/shadow-sm */
  box-shadow: 0px 1px 3px 0px var(--Colors-Effects-Shadows-shadow-sm_01, rgba(16, 24, 40, 0.10)), 0px 1px 2px 0px var(--Colors-Effects-Shadows-shadow-sm_02, rgba(16, 24, 40, 0.06));
}

/* Hover state for active buttons */
.user-type-selector button.active:hover {}

/* user-type-selector button hover */
.user-type-selector button:hover:not(.active) {
  background-color: #f0f0f0;
}

.form-group, .form-group input, .submit-btn, .google-btn {
  width: 100%;
  /* Adjust this value as needed */
  margin-left: auto;
  margin-right: auto;
}

.form-group input, .submit-btn, .google-btn {
  box-sizing: border-box;
  padding: 10px;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group form {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
}

.form-group input {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 14px;
  font-size: 16px;
  border-radius: var(--radius-md, 8px);
  border: 1px solid var(--Colors-Border-border-primary, #D0D5DD);
  background: var(--Colors-Background-bg-primary, #FFF);
  box-shadow: 0px 1px 2px 0px var(--Colors-Effects-Shadows-shadow-xs, rgba(16, 24, 40, 0.05));
}

.password-requirement {
  font-size: 0.8rem;
  color: #666;
}

.submit-btn {
  width: 100%;
  /* background-color: #4CAF50; */
  border: none;
  cursor: pointer;
  display: flex;
  padding: 10px var(--spacing-xl, 16px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-sm, 6px);
  align-self: stretch;
  border-radius: var(--radius-md, 8px);
  background: var(--Primary-Brand, #009A6B);
  color: var(--Component-colors-Components-Buttons-Primary-button-primary-fg, #FFF);
  margin-bottom: 16px;

  /* Text md/Semibold */
  font-family: var(--Font-family-font-family-body, Inter);
  font-size: var(--Font-size-text-md, 16px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--Line-height-text-md, 24px);
  /* 150% */
}

/* submit btn hover */
.submit-btn:hover {
  background: #007f5f;
}

.google-btn {
  width: 100%;
  padding: 0.75rem;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: flex;
  padding: 10px var(--spacing-xl, 16px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-lg, 12px);
  align-self: stretch;
  border-radius: var(--radius-md, 8px);
  border: 1px solid var(--Colors-Border-border-primary, #D0D5DD);
  background: var(--Colors-Background-bg-primary, #FFF);
  color: var(--black-100, #1C1C1C);
  /* Text md/Semibold */
  font-family: var(--Font-family-font-family-body, Inter);
  font-size: var(--Font-size-text-md, 16px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--Line-height-text-md, 24px);
  /* 150% */
}

/* Google buton hover */
.google-btn:hover {
  background: #f0f0f0;
  background: var(--Primary-Light, #F9FAFC);
  border: 1px solid #e0e0e0;
}

.google-btn img {
  margin-right: 0.5rem;
}

.google-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.login-link {
  text-align: center;
  margin-top: 1rem;
  color: var(--black-60, rgba(28, 28, 28, 0.60));

  /* Text sm/Regular */
  font-family: var(--Font-family-font-family-body, Inter);
  font-size: var(--Font-size-text-sm, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--Line-height-text-sm, 20px);
  /* 142.857% */
}

/* href of login-link must be in green */
.login-link a {
  color: #009A6B;
  text-decoration: none;
  font-family: var(--Font-family-font-family-body, Inter);
  font-size: var(--Font-size-text-sm, 14px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--Line-height-text-sm, 20px);
  /* 142.857% */
}

.error-message {
  color: red;
  margin-top: 10px;
}


.testimonial {

}

.testimonial-text {
  font-family: var(--Font-family-font-family-display, Inter);
  font-size: var(--Font-size-display-sm, 30px);
  font-style: normal;
  font-weight: 500;
  line-height: var(--Line-height-display-sm, 38px);
  /* 126.667% */
}

.testimonial-author {
  display: flex;
  flex-direction: column;
}

.author-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  flex-wrap: wrap;
  /* Allow wrapping if space is tight */

}

.author-name {
  color: var(--black-100, #1C1C1C);

  /* Text lg/Semibold */
  font-family: var(--Font-family-font-family-body, Inter);
  font-size: var(--Font-size-text-lg, 18px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--Line-height-text-lg, 28px);
  /* 155.556% */
}

.rating {
  white-space: nowrap;
  /* Prevent stars from wrapping */
}

.author-title {
  color: rgba(28, 28, 28, 0.60);
  font-family: var(--Font-family-font-family-body, Inter);
  font-size: var(--Font-size-text-md, 16px);
  font-style: normal;
  font-weight: 500;
  line-height: var(--Line-height-text-md, 24px);
}

.signin-info .app-preview {
  position: relative;
  /* Add this */
  width: 110%;
  height: 0;
  padding-bottom: 75%;
  /* Adjust this value to control the aspect ratio */
  overflow: visible;
  object-fit: cover;
  /* Change this from hidden to visible */
}

.app-preview-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 110%;
  /* Make the image slightly larger than its container */
  height: 110%;
  object-fit: cover;
  object-position: top left;
}


@media (max-width: 768px) {
  .signup-container {
    flex-direction: column;
  }

  .signup-info {
    display: none;
  }

  .signup-form, .signin-form {
    padding: 1rem;
    margin: 0 auto;
  }
}

@media (max-width: 1024px) {
  .app-preview {
    height: 300px;
    /* Smaller height for medium screens */
  }
}

@media (max-width: 768px) {
  .app-preview {
    height: 200px;
    /* Even smaller for mobile screens */
  }
}

@media (max-width: 1024px) {
  .app-preview-image {
    width: 100%;
    /* Make the image even larger on medium screens */
    height: 100%;
  }
}

@media (max-width: 768px) {

  html,
  body {
    overflow-y: hidden;
  }

  .signup-container {
    min-height: 100vh;
    height: auto;
  }

  .signup-form {
    padding: 1rem;
    overflow-y: visible;
  }
}

@media (max-width: 768px) {
  .signin-form .suma-logo {
    width: 150px;
    margin-bottom: 1rem;
    margin-left: 0px;
    position: static;
    margin-top: 16px;
    margin-bottom: 1rem;
    align-self: flex-start;
  }

  .signup-header {
    margin-bottom: 0.5rem;
  }

  .signup-header h1 {
    font-size: 1.75rem;
    margin-bottom: 0.25rem;
  }

  .signup-header p {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
  }

  .user-type-selector {
    margin-bottom: 0.75rem;
  }

  .form-group {
    margin-bottom: 0.75rem;
  }

  .submit-btn,
  .google-btn {
    padding: 8px;
  }

  .password-requirement {
    font-size: 0.7rem;
    margin-top: 0.25rem;
  }

  .submit-btn,
  .google-btn {
    margin-bottom: 0.75rem;
  }

  .login-link {
    margin-top: 0.75rem;
  }
}


/* Add these media queries at the end of your SignIn.css file */

@media (max-width: 768px) {

  html,
  body {
    overflow-y: auto;
  }

  .signin-container {
    flex-direction: column;
    min-height: 100vh;
    height: auto;
  }

  .signin-form {
    /* padding: 1rem; */
    min-width: 358px;
    max-width: 480px;
  }

  .signin-info {
    display: none;
  }

  .suma-logo {
    width: 80px;
    margin-bottom: 1rem;
  }

  .signin-header {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }

  .signin-header h1 {
    font-size: 1.75rem;
    margin-bottom: 0.25rem;
  }

  .signin-header p {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
  }

  .form-group {
    margin-bottom: 0.75rem;
  }

  .submit-btn,
  .google-btn {
    padding: 8px;
  }

  .submit-btn,
  .google-btn {
    margin-bottom: 0.75rem;
  }

  .signup-link {
    margin-top: 0.75rem;
  }
}

@media (max-width: 480px) {
  .signin-header h1 {
    font-size: 1.5rem;
  }

  .signin-header p {
    font-size: 0.8rem;
  }

  .submit-btn,
  .google-btn {
    font-size: 14px;
  }
}


/* CSS for the new user type modal */

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(28, 28, 28, 0.4);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  padding: 16px;
}

.modal-content {
  background: white;
  padding: 32px;
  border-radius: 12px;
  width: 100%;
  max-width: 480px;
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 
              0px 20px 24px -4px rgba(16, 24, 40, 0.08);
  animation: modalAppear 0.2s ease-out;
}

@keyframes modalAppear {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.modal-content h2 {
  color: var(--black-100, #1C1C1C);
  font-family: Inter, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 8px;
}

.modal-content p {
  color: var(--black-60, rgba(28, 28, 28, 0.60));
  font-family: Inter, sans-serif;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 32px;
}

/* User type selector in modal */
.modal-content .user-type-selector {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 32px;
  background: transparent;
  border: none;
  padding: 0;
}

.type-btn {
  width: 100%;
  padding: 16px;
  border: 1px solid var(--black-20, rgba(28, 28, 28, 0.20));
  border-radius: 8px;
  background: white;
  text-align: left;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
  transition: all 0.2s ease;
  color: var(--black-100, #1C1C1C);
}

.type-btn.active {
  border-color: var(--Primary-Brand, #009A6B);
  background-color: var(--Primary-Light, #F9FAFC);
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 
              0px 1px 2px rgba(16, 24, 40, 0.06);
}

.type-btn:hover:not(.active) {
  background-color: var(--black-5, rgba(28, 28, 28, 0.05));
}

.type-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 32px;
}

.modal-cancel {
  padding: 10px 18px;
  border: 1px solid var(--black-20, rgba(28, 28, 28, 0.20));
  border-radius: 8px;
  background: white;
  color: var(--black-100, #1C1C1C);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.modal-cancel:hover {
  background-color: var(--black-5, rgba(28, 28, 28, 0.05));
}

.modal-submit {
  padding: 10px 18px;
  background: var(--Primary-Brand, #009A6B);
  color: white;
  border: none;
  border-radius: 8px;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  min-width: 100px;
}

.modal-submit:hover:not(:disabled) {
  background: var(--Primary-Dark, #007A54);
}

.modal-submit:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .modal-content {
    padding: 24px;
    margin: 16px;
  }

  .modal-content h2 {
    font-size: 20px;
    line-height: 28px;
  }

  .type-btn {
    padding: 14px;
    font-size: 14px;
  }

  .modal-actions {
    margin-top: 24px;
  }
}