.error-404-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: white;
    padding: 1rem;
  }
  
  .error-404-content {
    max-width: 768px;
    width: 100%;
    text-align: left;
  }
  
  .error-404-label {
    color: #009A6B;
    font-family: var(--Font-family-font-family-body, Inter);
    font-size: var(--Font-size-text-md, 16px);
    font-weight: 600;
    line-height: var(--Line-height-text-md, 24px);
    margin-bottom: 1rem;
  }
  
  .error-404-title {
    color: #101828;
    font-family: var(--Font-family-font-family-display, Inter);
    font-size: 60px;
    font-weight: 600;
    line-height: 72px;
    letter-spacing: -1.2px;
    margin-bottom: 1.5rem;
  }
  
  .error-404-description {
    color: #475467;
    font-family: var(--Font-family-font-family-body, Inter);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 2rem;
  }
  
  .error-404-buttons {
    display: flex;
    justify-content: left;
    gap: 1rem;
  }
  
  .error-404-button {
    display: flex;
    padding: 16px 22px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: var(--radius-lg, 10px);
    font-family: var(--Font-family-font-family-body, Inter);
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
  }
  
  .go-back-button {
    border: 1px solid #D0D5DD;
    background-color: #FFF;
    color: #344054;
    box-shadow: 0px 0px 0px 1px rgba(16, 24, 40, 0.18) inset, 
                0px -2px 0px 0px rgba(16, 24, 40, 0.05) inset, 
                0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
  
  .take-home-button {
    background-color: #009A6B;
    color: #FFF;
    border: none;
  }
  
  .go-back-icon {
    width: 24px;
    height: 24px;
  }
  
  @media (max-width: 640px) {
    .error-404-title {
      font-size: 3rem;
      line-height: 1.2;
    }
  
    .error-404-description {
      font-size: 1.125rem;
    }
  
    .error-404-buttons {
      flex-direction: column;
    }
  }

  