.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
}

.text-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.header-container .title {
  display: flex;
  align-items: baseline;
  gap: 8px;
}

.header-container .main-title {
  color: var(--black-40, rgba(28, 28, 28, 0.40));
  font-feature-settings: 'ss01' on, 'cv01' on, 'cv11' on;
  font-family: Inter, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}

.header-container .sub-title {
  color: var(--black-100, #1C1C1C);
  font-feature-settings: 'ss01' on, 'cv01' on, 'cv11' on;
  font-family: Inter, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}

.header-container .subtitle {
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--black-40, rgba(28, 28, 28, 0.4));
}

.help-page {
  color: var(--primary-brand, #009A6B);
}

.button-container {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  background: var(--primary-brand, #009A6B);
  border-radius: 8px;
  color: var(--white-100, #FFFFFF);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button-container:hover {
  background-color: #007f5b;
}

.button-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-icon {
  width: 16px;
  height: 16px;
}

@media (max-width: 768px) {
  .header-container {
    flex-direction: column;
    align-items: stretch;
    gap: 16px;
  }

  .text-container {
    width: 100%;
  }

  .header-container .title {
    align-items: flex-start;
  }

  .header-container .main-title,
  .header-container .sub-title {
    font-size: 20px;
    line-height: 28px;
  }

  .header-container .subtitle {
    font-size: 12px;
    line-height: 18px;
  }

  .button-container {
    width: 100%;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
  }
}