.filter-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px; 
    background-color: #f7f9fb;
    border-radius: 8px;
    padding: 8px 16px;
  }
  
.table-header {
    justify-content: space-between;
    color: var(--black-40, rgba(28, 28, 28, 0.40));
    font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
    /* 12 Regular */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
}


.filter-dropdown {
    /* position: relative; */
    overflow: visible;
  }

.dropdown {
    position: relative;
    display: inline-block;
    z-index: 10;
}

.dropdown-content {
    /* display: none; */
    position: absolute;
    min-width: 250px;
    max-height: 50vh; /* Set the max height of the dropdown */
    overflow-y: auto; /* Enable vertical scrolling */
    z-index: 4;
    border-radius: var(--12, 12px);
    border: 1px solid var(--black-10, rgba(28, 28, 28, 0.10));
    background: linear-gradient(0deg, var(--white-20, rgba(255, 255, 255, 0.20)) 0%, var(--white-20, rgba(255, 255, 255, 0.20)) 100%), var(--white-100, #FFF);
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.10);
    backdrop-filter: blur(20px);
    margin-top: 4px;
    overflow: hidden;
    z-index: 1000;
}

.dropdown-content-scrollable {
    display: flex;
    flex-direction: column;
    max-height: 60vh;
    overflow: visible;
}

.dropdown-scroll-area {
    overflow-y: auto;
    max-height: calc(60vh - 60px); /* Adjust based on the height of your action buttons */
    padding: 8px 0;
}


.dropdown-content-dates {
    right: 0; /* Ensure it aligns to the right of the button */
}

.active-date-filter {
    background: var(--black-5, rgba(28, 28, 28, 0.05));
}

.dropdown-content .dropdown-item {
    display: flex;
    align-items: center;
    color: black;
    padding: 8px 16px;
    text-decoration: none;
    color: var(--black-100, #1C1C1C);
    font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
    /* 12 Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    text-align: left; /* Align text to the left */
}

.dropdown-content .dropdown-item:first-child {
    margin-top: 8px; /* Add some space at the top */
}

.dropdown-content .dropdown-item:last-child {
    margin-bottom: 8px; /* Add some space at the top */
}


.dropdown-content .dropdown-item-dates {
    justify-content: space-between; /* This will separate the text and the dates */

}

.date-display {
    font-size: 12px;
}

.dropdown-content .dropdown-item input {
    margin-right: 8px;
}

/* Add hover effect */
.dropdown-content .dropdown-item:hover {
    background: var(--black-5, rgba(28, 28, 28, 0.05));
}

.dropdown-content .action-buttons {
    padding: 8px;
    white-space: nowrap;
}

.custom-date-picker {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; /* Make it span the entire width */
    background: #ffffff; /* Background color */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1); /* Add some shadow for better look */
}

.custom-dates-dropdown {
    min-width: 400px; /* Adjust this value as needed */
}

.custom-date-picker button {
    margin-top: 16px;
    color: #ffffff; /* White text color */
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    color: rgba(28, 28, 28, 1);
    cursor: pointer;
    color: var(--black-100, #1C1C1C);
    font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
    top: -17px;
}


.react-datepicker {
    border: none;
}

.date-input-button {
    margin-top: 0px!important;
}

.react-datepicker__week, .react-datepicker__day-names {
    white-space: nowrap;
    align-items: flex-start;
    display: flex;
    justify-content: space-around;
}

.react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
    background: rgba(0, 154, 107, 1);
    color: #fff!important;
    font-weight: bold;
}

.react-datepicker__header {
    text-align: center;
    background-color: #fff;
    border-bottom: 1px solid var(--black-10, rgba(28, 28, 28, 0.10));
    padding: 8px 0;
    position: relative;
}


.custom-date-picker button:hover {
    background: rgba(0, 154, 107, 0.8); /* Darker hover effect */
}


.date-input-button {
    flex: 1;
    text-align: center;
    padding: 10px;
    border: 1px solid #009A6B; /* Primary brand color */
    border-radius: 4px;
    color: rgba(28, 28, 28, 1);
    background-color: rgba(249, 250, 252, 1);
    cursor: pointer;
    transition: background-color 0.3s ease;
    color: var(--black-100, #1C1C1C);
    font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;

    /* 14 Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    border-radius: var(--Radius-4, 4px);
}

.date-input-button:hover {
    background-color: rgba(0, 154, 107, 0.8); /* Darker hover effect */
}

.date-inputs {
    display: flex;
    justify-content: space-between;
    width: 100%;    
}

.date-input-button:last-child {
    margin-right: 0;
}

.react-datepicker {
    width: 100%;
}

.react-datepicker__month-container {
    width: 100%;
}

/* Change the background color of the selected day to green */
.react-datepicker__day--selected {
    /* border-radius: var(--12, 12px); */
    background: var(--Primary-LightGreen, #BEE7C6);
    color: var(--black-100, #1C1C1C);
    text-align: center;
    font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
    /* 12 Regular */
   
}

.react-datepicker__day--selected:hover {
    border-radius: var(--12, 12px);
    background: rgba(0, 154, 107, 1);
}

.react-datepicker__day--selected:active {
    border-radius: var(--12, 12px);
    background: rgba(0, 154, 107, 1);
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
    background: rgba(0, 154, 107, 1);
    color: var(--black-100, #1C1C1C);
}

.react-datepicker__day--in-range:hover {
    background: #009A6B;
}   


/* Adjust the width of the date picker */
.custom-date-picker .react-datepicker {
    width: 100%;
}

.custom-date-picker .react-datepicker__month-container {
    width: 100%;
}


.checkbox-label {
    display: flex;
    align-items: left;
    flex: 1;
}

button.apply-button {
    margin-top: 10px;
    width: 100%;
    padding: 10px;
    background-color: rgba(0, 154, 107, 1);
    
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button.apply-button:hover {
    background-color: rgba(0, 154, 107, 1)
}   


.checkbox-label input {
    display: none;
}

.checkbox-custom {
    display: inline-flex;
    align-items: center;
    margin-right: 8px;
    position: relative;
    width: 20px;
    height: 20px;
}

.checkbox-icon,
.checkbox-off-icon {
    position: absolute;
    width: 100%;
    height: 100%;
}


.checkbox-icon {
    display: none;
}

.checkbox-off-icon {
    display: inline;
}

.checkbox-label input:checked + .checkbox-custom .checkbox-icon {
    display: inline;
}

.checkbox-label input:checked + .checkbox-custom .checkbox-off-icon {
    display: none;
}

.list-icon {
    margin-left: auto; /* Push the icon to the far right */
    width: 16px; /* Adjust icon size as needed */
    height: 16px; /* Adjust icon size as needed */
    vertical-align: middle; /* Ensures the icon is vertically aligned with the text */
}

  
.table-header-left {
    display: flex;
    align-items: center;
}

.header-right {
    margin-left: auto;
}


.header-icons {
    display: flex;
    align-items: center;
    gap: 8px; /* Space between the icons */
}




.header-icons .icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    padding: 4px;
    border-radius: 4px;
    background: var(--Primary-Brand, #009A6B);
    border: none;
}


.header-icons .icon-button img {
    width: 20px;
    height: 20px;
}

.action-buttons {
    display: flex;
    gap: 10px;
    display: flex;
}


.action-button{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px; /* Space between icon and text */
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    height: var(--Size-28, 28px);
    padding: var(--4, 4px) var(--12, 12px);
    justify-content: center;
    align-items: center;
    gap: var(--4, 4px);
    border-radius: var(--8, 8px);
    background: var(--black-10, rgba(28, 28, 28, 0.10));
    color: var(--black-80, rgba(28, 28, 28, 0.80));
    text-align: center;
    font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;

    /* 12 Semibold */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 150% */
    transition: background-color 0.3s ease  ;

}

.action-button:hover {
    border-radius: var(--12, 12px);
    background: var(--black-10, rgba(28, 28, 28, 0.40));
}


.header-search {
    display: flex;
    align-items: center;
    gap: 8px;
}

.header-icons,
.header-search,
.header-filters {
    display: flex;
    align-items: center;
    gap: 8px;
}


.search-box {
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 4px 8px;
    background-color: var(--white-100, #FFF);
  }
  
.search-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}
  
.search-input {
    border: none;
    outline: none;
    padding: 8px;
    width: 200px;
}

.header-filters {
    display: flex;
    gap: 8px;
    
}

.filter-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 8px;
    border: 1px solid rgba(28, 28, 28, 0.1);
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    gap: 8px;
    color: #1C1C1C; /* Text color */
    min-width: 154px;
}


/* filter-butto hover */
.filter-button:hover {
    background: var(--white-80, rgba(255, 255, 255, 0.80));
    border: 1px solid var(--Primary-MediumGreen, #79CD8F);

}


.filter-button .filter-button-content img:first-child {
    width: 20px;
    height: 20px;
}
  
.filter-button img:last-child {
    width: 12px;
    height: 12px;
}

.filter-button-content {
    display: flex;
    align-items: center;
    gap: 8px;
}


.table-container {
    overflow: hidden;
    border-radius: 8px;
    gap: 8px;
}

.table-content {
    /* flex: 1; */
    overflow: auto;
    /* display: flex; */
    /* flex-direction: column; */
    z-index: 1;
}

.table-wrapper { 
    flex: 1;
    display: flex;    
    overflow-x: auto;
    overflow-y: auto;
    border: 1px solid #E4E7EC!important;
    border-radius: 8px;
    background-color: #fff;

}

.table-wrapper table {
    flex: 1; /* Take up all available space */
    width: auto;
    min-width: 100%;
    table-layout: auto;
    border-collapse: separate;
    border-spacing: 0;
}

.table-wrapper tbody{
    flex: 1;
}


.table-wrapper thead {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #f7f9fb;
}

.table-wrapper thead th {
    position: sticky;
    top: 0;
    background-color: #f7f9fb;
    z-index: 3;
    font-size: 12px;
}


.table-wrapper th,
.table-wrapper td {
    padding: 12px;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;
    background-color: #fff;
    max-width: 200px;
    min-width: 20px;
    overflow: hidden;
    text-transform: capitalize;
}


.table-spacer {
    flex-grow: 1;
  }

/* Add specific widths for other columns as needed */
/* Adjust column widths */
.invoice-id {
    min-width: 200px;
    text-transform: none!important;
}

.invoice-number-column {
    text-transform: none!important;
}

.tax-id-number {
    text-transform: none!important;
}

.category-badge span {
    display: inline !important;
    display: flex;
    padding: 2px 8px;
    align-items: center;
    border-radius: 12px;
    border: 1px solid var(--black-5, rgba(28, 28, 28, 0.05));
    background: var(--Primary-Light, #F9FAFC);
    color: var(--black-80, rgba(28, 28, 28, 0.80));
    /* 12 Medium */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    /* 150% */
}

.date-cell {

}

.td-styled {

}

.total-invoiced {

}

.supplier-name-column {

}

.currency-column {

}

.subtotal-subject-to-vat-column {

}

.total-vat-tax-column {

}

.date-paid-column {

}

.payment-deadline-column {

}

.supplier-address-column {

}

.country-column {

}

.payment-account-column {

}

.subtotal-not-subject-to-vat-column {

}

.exchange-rate-column {

}

.supplier-email-column {

}

.supplier-phone-column {

}

.additional-information-column {

}

.payment-method-column {

}

.payment-terms-column {

}

.vat-tax-percentage-column {

}

.supplier-id-column {

    text-transform: none!important;
}

.supplier-category-id-column {

    text-transform: none!important;
}

.status-column {

}

.file-url-column {

}


.table-wrapper th:first-child,
.table-wrapper td:first-child {
    position: sticky;
    left: 0;
}

.table-wrapper td:nth-child(-n+2) {
    position: sticky;
    z-index: 1; 

}

.table-wrapper th:nth-child(-n+2){
    position: sticky;
    z-index: 4;
}


/* Adjust the left position for the second column */
.table-wrapper th:nth-child(2),
.table-wrapper td:nth-child(2) {
    left: 44px; /* Adjust this value based on the width of your checkbox column */
}
  
.table-header {
    background-color: #f7f9fb;
    border-collapse: collapse!important; 
    color: #1C1C1C;
    font-size: 14px;
}

.table-header a {
    font-weight: bold;
    color: #009A6B;
    text-decoration: none;
}

.table-header a:hover {
    color: #166138;
}

.table-header th {
    padding: 12px;
    text-align: left;
    color: #1C1C1C; /* Header text color */
    font-weight: bold;
    background: var(--Primary-Light, #F9FAFC);
    border-right: 0px solid #ddd; /* Add a right border to each header cell */
    white-space: nowrap;
    cursor: pointer;

}
  
.table-header th .table-header-icon {
    width: var(--16, 16px);
    height: var(--16, 16px);
    justify-content: center;
    vertical-align: middle;
    flex-shrink: 0;
    margin-left : 8px;
}

tbody tr {
    border-bottom: 1px solid #E4E7EC!important;
    cursor: pointer;
}

tbody tr:last-child {
    border-bottom: none!important;
}

tbody tr:hover td{
    background-color: #F2FAF4!important;
}

tbody td {
    padding: 12px;
    min-height: 64px; /* Ensure consistent row height */
}

.file-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
}
 
.invoice-details {
    display: flex;
    flex-direction: column;
}

.file-name {
    font-weight: bold;
    display: inline-block;
    max-width: 150px; 
    text-align: left;
    color: var(--black-80, rgba(28, 28, 28, 0.80));
    font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
    /* 14 Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}
  
.file-size {
    font-size: 12px;
    color: var(--black-60, rgba(28, 28, 28, 0.60));
    font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
    /* 12 Regular */
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
}
  
.icon-wrapper {
    display: flex;
    align-items: center;
    position: relative; 
    width: 100%; 
    justify-content: flex-start;
}

.file-icon-table {
    width: 40px; /* Adjust the size as needed */
    height: 40px; /* Adjust the size as needed */
    margin-right: 8px;
    background: none;
}


.action-icon {
    width: 20px;
    height: 20px;
}
    

.invoice-id {
    align-items: center;
}



.invoice-icon {
    width: 40px; /* Increase the size of the icon */
    height: 40px;
    margin-right: 8px;
}
  

  
.tax-id-number {
    min-width: 80px;
    max-width: 220px;
    justify-content: center;
    align-items: flex-start;
    color: var(--black-80, rgba(28, 28, 28, 0.80));
    font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;

    /* 14 Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    
}
  

  
.date-cell {
    align-items: center;
    gap: 8px;
    white-space: nowrap; /* Prevent wrapping to the next line */
    color: var(--black-80, rgba(28, 28, 28, 0.80));
    font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;

    /* 14 Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}
  
.date-icon {
    width: 16px;
    /* height: 16px; */
    margin-bottom: -2px;
    margin-right: 4px;
    text-align: center;

}

.td-styled {
    padding: 16px 12px; /* var(--16, 16px) var(--12, 12px); */
    flex-direction: column;
    justify-content: center;
    
    color: var(--black-80, rgba(28, 28, 28, 0.80));
    font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
    /* 14 Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.total-invoiced {
    font-weight: 600;

}
  
.table-pagination {
    margin-top: auto; /* Push to the bottom */
    padding: 8px 0;
    display: flex;
    justify-content: center;
    gap: 8px;
    padding: 8px 0;
    margin-bottom: 32px;
}

.pagination-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    border-radius: 8px;
    border: 1px solid var(--black-5, rgba(28, 28, 28, 0.05));
    background: var(--Primary-Light, #F9FAFC);
    color: var(--black-60, #1C1C1C);
    cursor: pointer;
    padding: 4px;
    gap: 4px;
}

.pagination-button.active {
    background: var(--Primary-MediumGreen, #009A6B);
    border: 1px solid var(--white-20, rgba(255, 255, 255, 0.2));
    color: var(--white-100, #FFF);
}


.selected-row {
    position: relative;
    background-color: #fff;   
}

.selected-row td {
    position: relative; 
    background-color: #F2FAF4!important;
}

.selected-row td::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}


/* Add specific background color for sticky columns in selected rows */
.selected-row td:nth-child(-n+2)::before {
    z-index: 2;
}


.table-header-icon {
    transition: transform 0.3s;
}
  
.table-header-icon-asc {
    transform: rotate(180deg);
}


/* Add these styles to your CSS file */
.droppable-area {
    background-color: white; /* Default background color */
}

.droppable-area.dragging-over {
    background-color: #f0f0f0; /* Highlight color when dragging over */
}

.draggable-item {
    background-color: white; /* Default background color */
}

.draggable-item.dragging {
    background-color: #e0e0e0; /* Highlight color when dragging */
}

.placeholder {
    background-color: #d0d0d0; /* Placeholder color */
    border: 2px dashed #a0a0a0;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.table-container .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 16px;
    width: 365px;
    gap: 10px;
}

.table-container .modal-content p, .table-container .modal-content h2 {
    margin: 0px;
}

.table-container .modal-content p {
    line-height: 22px;
    font-size: 14px;
    color: rgba(28, 28, 28, 0.60);
}

.modal-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.modal-actions button {
    margin-right: 10px;
    padding: 10px 16px;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
}

.modal-actions button:first-child {
    background-color: rgba(28, 28, 28, 0.10);
    color: rgba(28, 28, 28, 0.80);
}

.modal-actions button:last-child {
    background-color: #EE7C30;
    color: white;
}


.modal-actions button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.snackbar {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    border-radius: 4px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1001; /* Ensure it's above other elements */
    min-width: 250px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.snackbar.success {
    background-color: #009A6B;
}

.snackbar.error {
    background-color: #f44336;
}

.snackbar button {
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
    margin-left: 10px;
}

.per-page-dropdown {
    position: relative;
}

.per-page-dropdown .dropdown-content-up {
    position: absolute;
    bottom: 100%; /* Position the dropdown above the button */
    left: 0;
    margin-bottom: 5px; /* Add some space between the button and the dropdown */
    /* Add other styles as needed (background, border, etc.) */
}

.per-page-dropdown .rotated {
    transform: rotate(180deg);
}


.category-name-cell {
    display: flex;
    align-items: center;
}

.category-details {
    display: flex;
    flex-direction: column;
}

.category-name {
    color: var(--black-80, rgba(28, 28, 28, 0.80));
    font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.category-id {
    color: var(--black-60, rgba(28, 28, 28, 0.60));
    font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}


/* Empty state */
.empty-state-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%; /* Ensure it takes full width */
    padding: 40px;
    position: relative;
    text-align: center;
}

.circular-lines {
    position: absolute;
    top: 24%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1; /* Send lines to the background */

}

.circular-line {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-shrink: 0;
    stroke: var(--Colors-Border-border-secondary, #E4E7EC);
    flex-shrink: 0;
    fill: none; /* Ensure there's no fill color affecting appearance */
    opacity: 1; /* Default opacity */
}

.line-96 {
    width: 96px;
    height: 96px;
    stroke-width: 2px; /* Thicker stroke for smallest circle */
    opacity: 0.8; /* Reduce opacity slightly */
}

.line-160 {
    width: 160px;
    height: 160px;
    stroke-width: 1.5px; /* Slightly thinner */
    opacity: 0.6; /* Reduce opacity slightly */

}

.line-224 {
    width: 224px;
    height: 224px;
    stroke-width: 1.2px; /* Thinner */
    opacity: 0.5; /* Reduce opacity slightly */

}

.line-288 {
    width: 288px;
    height: 288px;
    stroke-width: 1px; /* Thinner stroke */
    opacity: 0.4; /* Reduce opacity slightly */

}

.line-352 {
    width: 352px;
    height: 352px;
    stroke-width: 0.8px; /* Very thin stroke */
    opacity: 0.25; /* Reduce opacity slightly */
}

.line-416 {
    width: 416px;
    height: 416px;
    stroke-width: 0.5px; /* Thinnest stroke */
    opacity: 0.1; /* Make the outermost circle the least bold */
}

.search-icon-container {
    display: flex;
    width: 48px;
    height: 48px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    border-radius: var(--radius-lg, 10px);
    border: 1px solid var(--Component-colors-Components-Icons-Featured-icons-Modern-featured-icon-modern-border, #E4E7EC);
    background: var(--Colors-Background-bg-primary, #FFF);
    box-shadow: 0px 0px 0px 1px var(--Colors-Effects-Shadows-shadow-skeumorphic-inner-border, rgba(16, 24, 40, 0.18)) inset, 0px -2px 0px 0px var(--Colors-Effects-Shadows-shadow-skeumorphic-inner, rgba(16, 24, 40, 0.05)) inset, 0px 1px 2px 0px var(--Colors-Effects-Shadows-shadow-xs, rgba(16, 24, 40, 0.05));
    margin-bottom: 24px;
    z-index: 1; /* Ensure the search icon is above the lines */

}

.search-icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;

}

.empty-state-title,
.empty-state-description,
.add-new-invoice-button {
    z-index: 2; /* Ensure text and button are above the lines */
}

.empty-state-title {
    color: var(--black-100, #1C1C1C);
    text-align: center;
    font-family: var(--Font-family-font-family-body, Inter);
    font-size: var(--Font-size-text-md, 16px);
    font-style: normal;
    font-weight: 600;
    line-height: var(--Line-height-text-md, 24px);
    margin-bottom: 8px;
}

.empty-state-description {
    color: var(--black-60, rgba(28, 28, 28, 0.60));
    text-align: center;
    font-family: var(--Font-family-font-family-body, Inter);
    font-size: var(--Font-size-text-sm, 14px);
    font-style: normal;
    font-weight: 400;
    line-height: var(--Line-height-text-sm, 20px);
    margin-bottom: 24px;
}

.add-new-invoice-button {
    display: inline-flex;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: var(--radius-lg, 8px);
    background: var(--Primary-Brand, #009A6B);
    color: var(--white-100, #FFF);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.add-new-invoice-button:hover {
    background: var(--Primary-Brand-Hover, #00845A);
}



@media (max-width: 768px) {
    .table-container {
        padding: 0;
        width: 100%;
        overflow-x: hidden;
    }
    
    .table-content {
        width: 100%; /* Ensure full width */
    }
    
    .table-wrapper {
        width: 100%; /* Ensure full width */
        overflow-x: hidden; /* Prevent horizontal scrolling */
    }

    .mobile-table {
        width: 100%; /* Ensure full width */
        border: none; /* Remove border as it might be causing spacing issues */
        border-radius: 0; /* Remove border-radius */
        /* padding: 0; */
    }

    .mobile-table-header,
    .mobile-row {
        display: flex;
        width: 100%;
        padding: 12px 16px;
        box-sizing: border-box;
    }


    .mobile-table-header {
        /* display: flex; */
        background-color: #f7f9fb;
        border-bottom: 1px solid #E4E7EC;
    }

    .mobile-header-cell,
    .mobile-cell {
        overflow: hidden;
    }

    .mobile-header-cell.file-name,
    .mobile-cell.file-name {
        flex: 3;
        padding-right: 8px;
    }
    
    .mobile-header-cell.amount,
    .mobile-cell.amount {
        flex: 1;
        text-align: right;
    }

    .mobile-header-cell {
        flex: 1;
        color: #1C1C1C;
        font-weight: bold;
        font-size: 12px;
        line-height: 18px;
    }
    
    .mobile-header-cell.amount {
        text-align: right;
    }

    .mobile-row {
        /* display: flex; */
        border-bottom: 1px solid #E4E7EC;
    }

    .mobile-row:last-child {
        border-bottom: none;
    }

    .mobile-cell {
        flex: 1;
        font-size: 14px;
        line-height: 20px;
        color: rgba(28, 28, 28, 0.80);
    }

    .mobile-cell.file-name {
        flex: 4;
        display: flex;
        align-items: center;
    }

    .mobile-cell.amount {
        text-align: right;
        flex: 1; /* Give more space to the file name */
    }

    .mobile-file-info {
        display: flex;
        align-items: center;
        /* width: 100 */
    }

    .mobile-file-info .file-icon-table {
        width: 24px;
        height: 24px;
        min-width: 24px;
        margin-right: 8px;
    }

    .mobile-file-info .file-name {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .mobile-file-info .file-size {
        font-size: 12px;
        color: #666;
    }

    .mobile-total {
        font-weight: normal;
    }
    
    .mobile-header-cell,
    .mobile-cell {
        overflow: hidden;
        flex: 1;
    }

    .mobile-header-cell.fileName,
    .mobile-cell.fileName,
    .mobile-header-cell.supplierName,
    .mobile-cell.supplierName,
    .mobile-header-cell.customerName,
    .mobile-cell.customerName,
    .mobile-header-cell.name,
    .mobile-cell.name {
        flex: 3;
        padding-right: 8px;
    }
    
    .mobile-header-cell.totalAmount,
    .mobile-cell.totalAmount,
    .mobile-header-cell.processedData\.total_amount_converted,
    .mobile-cell.processedData\.total_amount_converted {
        flex: 1;
        text-align: right;
    }

    .mobile-file-info,
    .mobile-cell span {
        display: flex;
        align-items: center;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}


@media (max-width: 768px) {
    .filter-section {
      flex-direction: column;
      align-items: stretch;
      padding: 8px;
    }
  
    .table-header-left,
    .header-right {
      width: 100%;
      margin-bottom: 8px;
    }
  
    .header-filters {
      flex-direction: column;
      width: 100%;
    }

    .header-icons .dropdown {
        display: none;
      }
  
    .filter-dropdown {
        position: relative;
        width: 100%;
        margin-bottom: 8px;
        box-sizing: border-box;
    }
  
    .filter-button {
      width: 100%;
      justify-content: space-between;
    }
  
    .dropdown-content {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: calc(85vw); /* Full viewport width minus some padding */
        max-width: 400px; /* Or whatever maximum width you prefer */
        max-height: 80vh;
        overflow-y: auto;
        z-index: 1000;
        border-radius: 8px;
        border: 1px solid rgba(28, 28, 28, 0.10);
        background: #FFF;
        box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.10);
        margin-top: 4px;
        box-sizing: border-box;
    }
  
    .icon-button {
      padding: 8px;
    }
  
    .normal-csv-button {
      width: 100%;
      justify-content: center;
    }

    .custom-date-picker {
        width: 90%;
        padding: 12px;
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      
      .react-datepicker {
        width: 100%;
        font-size: 14px;
        box-sizing: border-box;
    }
    
    .react-datepicker__month-container {
        width: 100%;
        box-sizing: border-box;
    }

    .react-datepicker__day-name, 
    .react-datepicker__day, 
    .react-datepicker__time-name {
        width: 14.2857%;
        margin: 0;
        padding: 6px 0;
        line-height: 1;
        box-sizing: border-box;
    }


      .react-datepicker__month {
        margin: 0;
      }
      
      
      .react-datepicker__header {
        padding-top: 6px;
      }
      
      .react-datepicker__day-name, .react-datepicker__day {
        width: 14.2857%;
        margin: 0;
        padding: 8px 0;
        line-height: 1;
      }
      
      .date-range-inputs {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        flex-wrap: wrap;
      }
      
      .date-input-button {

        width: calc(50% - 4px);
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        background: #f8f8f8;
        text-align: center;
        font-size: 12px;
        box-sizing: border-box;
      }
      
      .apply-button {
        width: 100%;
        padding: 10px;
        margin-top: 16px;
        background: #009A6B;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
      }

      .filter-section {
        overflow: visible;
        max-width: 100%;
        box-sizing: border-box;
      }

      .table-content {
        padding-bottom: 80vh;
      }
  }
