.simple-metrics-card {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: #F9FAFC;
  border-radius: 16px;
  gap: 8px;
  max-width: 250px;
  min-width: 160px;
  margin-right: 16px;
  margin-bottom: 16px;
  position: relative;
  flex: 1;
}

.metrics-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.metrics-title {
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #1C1C1C;
}

.metrics-amount {
  font-family: Inter, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  color: #1C1C1C;
}

.metrics-view-link {
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #009A6B;
  text-decoration: none;
  position: absolute;
  top: 16px;
  right: 16px;
}

.metrics-view-link:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .simple-metrics-card {
      padding: 12px;
      max-width: 100%;
      min-width: 0;
      margin-right: 0;
      margin-bottom: 12px;
  }

  .metrics-content {
      gap: 4px;
  }

  .metrics-title {
      font-size: 14px;
  }

  .metrics-amount {
      font-size: 20px;
      line-height: 28px;
  }
}
