.accept-invitation-container {
    display: flex;
    min-height: 100vh;
    overflow: hidden;
  }
  
  .accept-invitation-form {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 2rem;
    max-width: 500px; /* Reduce max-width */
    margin: 0 auto; /* Center the form */
    box-sizing: border-box; /* Include padding in width calculation */
  }
  
  
  .suma-logo {
    width: 140px;
    margin-bottom: 2rem;
  }
  
  .accept-invitation-header {
    width: 100%;
    min-width: 358px;
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    margin-bottom: 1rem;
    margin-top: 4rem;
  }
  
  .accept-invitation-header h1 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
  }
  
  .accept-invitation-header p {
    color: var(--black-60, rgba(28, 28, 28, 0.60));
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  
  .form-group, .form-group input, .submit-btn, .google-btn {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .form-group input, .submit-btn, .google-btn {
    box-sizing: border-box;
    padding: 10px;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }
  
  .form-group input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
  }
  
  .submit-btn {
    width: 100%;
    display: flex;
    padding: 10px var(--spacing-xl, 16px);
    justify-content: center;
    align-items: center;
    gap: var(--spacing-sm, 6px);
    align-self: stretch;
    border-radius: var(--radius-md, 8px);
    background: var(--Primary-Brand, #009A6B);
    color: var(--Component-colors-Components-Buttons-Primary-button-primary-fg, #FFF);
    margin-bottom: 16px;
    border: none;
    cursor: pointer;
    font-family: var(--Font-family-font-family-body, Inter);
    font-size: var(--Font-size-text-md, 16px);
    font-style: normal;
    font-weight: 600;
    line-height: var(--Line-height-text-md, 24px);
  }
  
  .submit-btn:hover {
    background: #007f5f;
  }
  
  .submit-btn:disabled {
    background: #cccccc;
    cursor: not-allowed;
  }
  
  .google-signin {
    margin-bottom: 1rem;
  }
  
  .google-btn {
    width: 100%;
    padding: 0.75rem;
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    gap: var(--spacing-lg, 12px);
    color: var(--black-100, #1C1C1C);
    font-family: var(--Font-family-font-family-body, Inter);
    font-size: var(--Font-size-text-md, 16px);
    font-style: normal;
    font-weight: 600;
    line-height: var(--Line-height-text-md, 24px);
  }
  
  .google-btn:hover {
    background: var(--Primary-Light, #F9FAFC);
    border: 1px solid #e0e0e0;
  }
  
  .google-btn:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  .google-btn img {
    margin-right: 0.5rem;
  }
  
  .error-message {
    color: #d32f2f;
    font-size: 0.875rem;
    margin-top: 0.5rem;
    text-align: center;
  }
  
  .toggle-form-link {
    text-align: center;
    margin-top: 1rem;
    color: var(--black-60, rgba(28, 28, 28, 0.60));
    font-family: var(--Font-family-font-family-body, Inter);
    font-size: var(--Font-size-text-sm, 14px);
    font-style: normal;
    font-weight: 400;
    line-height: var(--Line-height-text-sm, 20px);
  }
  
  .toggle-form-btn {
    background: none;
    border: none;
    color: #009A6B;
    cursor: pointer;
    font-family: var(--Font-family-font-family-body, Inter);
    font-size: var(--Font-size-text-sm, 14px);
    font-style: normal;
    font-weight: 600;
    line-height: var(--Line-height-text-sm, 20px);
    padding: 0;
    margin-left: 0.5rem;
  }
  
  
  


  .author-name {
    color: var(--black-100, #1C1C1C);
    font-family: var(--Font-family-font-family-body, Inter);
    font-size: var(--Font-size-text-lg, 18px);
    font-style: normal;
    font-weight: 600;
    line-height: var(--Line-height-text-lg, 28px);
  }
  
  .rating {
    white-space: nowrap;
  }
  
  .author-title {
    color: rgba(28, 28, 28, 0.60);
    font-family: var(--Font-family-font-family-body, Inter);
    font-size: var(--Font-size-text-md, 16px);
    font-style: normal;
    font-weight: 500;
    line-height: var(--Line-height-text-md, 24px);
  }

  .accept-invitation-info {
    flex: 1;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    max-width: 100%;
    padding: var(--spacing-9xl, 96px) var(--spacing-7xl, 64px) var(--spacing-none, 0px) var(--spacing-8xl, 80px);
    align-items: flex-start;
    gap: var(--spacing-9xl, 96px);
    flex: 1 0 0;
    align-self: stretch;
    background: var(--Colors-Background-bg-tertiary, #F2F4F7);
  }
  
  .accept-invitation-info .app-preview {
    position: relative;
    width: 110%;
    height: 0;
    padding-bottom: 75%;
    overflow: visible;
    object-fit: cover;
  }

  .testimonial-text {
    font-family: var(--Font-family-font-family-display, Inter);
    font-size: var(--Font-size-display-sm, 30px);
    font-style: normal;
    font-weight: 500;
    line-height: var(--Line-height-display-sm, 38px);
    /* 126.667% */
  }
  
  .testimonial-author {
    display: flex;
    flex-direction: column;
  }
  
  .author-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    flex-wrap: wrap;
    /* Allow wrapping if space is tight */
  }
  
  .author-name {
    color: var(--black-100, #1C1C1C);
    /* Text lg/Semibold */
    font-family: var(--Font-family-font-family-body, Inter);
    font-size: var(--Font-size-text-lg, 18px);
    font-style: normal;
    font-weight: 600;
    line-height: var(--Line-height-text-lg, 28px);
    /* 155.556% */
  }
  
  .rating {
    white-space: nowrap;
    /* Prevent stars from wrapping */
  }
  
  .author-title {
    color: rgba(28, 28, 28, 0.60);
    font-family: var(--Font-family-font-family-body, Inter);
    font-size: var(--Font-size-text-md, 16px);
    font-style: normal;
    font-weight: 500;
    line-height: var(--Line-height-text-md, 24px);
  }
  
  .accept-invitation-info .app-preview {
    position: relative;
    /* Add this */
    width: 110%;
    height: 0;
    padding-bottom: 75%;
    /* Adjust this value to control the aspect ratio */
    overflow: visible;
    object-fit: cover;
    /* Change this from hidden to visible */
  }
  
  .app-preview-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 110%;
    /* Make the image slightly larger than its container */
    height: 110%;
    object-fit: cover;
    object-position: top left;
  }

  .invitation-details {
    background: rgba(190, 231, 198, 0.50);
    border: 1px solid rgba(0, 154, 107, 0.50);
    border-radius: 10px;

    padding: 12px;
    margin-bottom: 24px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    color: var(--black-80, rgba(28, 28, 28, 0.80));
    font-feature-settings: 'ss01' on, 'cv01' on, 'cv11' on;
    /* 12 Regular */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
}

.invitation-details h2 {
    color: var(--black-100, #1C1C1C);
    /* 16 Semibold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.invitation-details p {
    color: var(--black-80, rgba(28, 28, 28, 0.80));
    font-feature-settings: 'ss01' on, 'cv01' on, 'cv11' on;
    
    /* 12 Regular */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
}
  
  
  @media (max-width: 480px) {
    .accept-invitation-header h1 {
      font-size: 1.5rem;
    }
  
    .accept-invitation-header p {
      font-size: 0.8rem;
    }
  
    .submit-btn,
    .google-btn {
      font-size: 14px;
    }
  }

  @media (max-width: 1024px) {
    .app-preview {
      height: 300px;
      /* Smaller height for medium screens */
    }
  }

  @media (max-width: 1024px) {
    .app-preview-image {
      width: 100%;
      /* Make the image even larger on medium screens */
      height: 100%;
    }
  }

  


  @media (max-width: 768px) {
    .accept-invitation-container {
      flex-direction: column;
      min-height: 100vh;
      height: auto;
    }
  
    .accept-invitation-info {
      display: none;
    }
  
    .accept-invitation-form {
      padding: 1rem;
      min-width: unset;
      max-width: 100%;
      width: 100%;
    }

    .form-group,
    .form-group input,
    .submit-btn,
    .google-btn {
        width: 100%;
        max-width: 100%; /* Ensure no overflow */
    }
  }
  
 
  
  @media (max-width: 768px) {
    .app-preview {
      height: 200px;
      /* Even smaller for mobile screens */
    }
  }
  
  
  
    
  @media (max-width: 768px) {
    html,
    body {
      overflow-y: auto;
    }
  
    .accept-invitation-container {
      flex-direction: column;
      min-height: 100vh;
      height: auto;
    }
  
    .accept-invitation-form {
      padding: 1rem;
      min-width: 358px;
      max-width: 480px;
    }
  
    .suma-logo {
        width: 80px;
        margin-bottom: 1rem;
        margin-left: 0px;
        position: static;
        margin-top: 16px;
        margin-bottom: 1rem;
        align-self: flex-start;
      }
  
    .accept-invitation-header {
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
    }
  
    .accept-invitation-header h1 {
      font-size: 1.75rem;
      margin-bottom: 0.25rem;
    }
  
    .accept-invitation-header p {
      font-size: 0.9rem;
      margin-bottom: 0.5rem;
    }
  
    .form-group {
      margin-bottom: 0.75rem;
    }

    .form-group input {
        width: 100%;
        box-sizing: border-box;
      }
    
  
    .submit-btn,
    .google-btn {
      padding: 8px;
      margin-bottom: 0.75rem;
      width: 100%;

    }
  
    .toggle-form-link {
      margin-top: 0.75rem;
    }
  }
