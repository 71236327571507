.metrics-cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 24px; /* Space between the cards */
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
  box-sizing: border-box;
}

@media (max-width: 1200px) {
  .metrics-cards-container {
      grid-template-columns: repeat(2, 1fr); /* Two cards per row */
  }
}

@media (max-width: 768px) {
  .metrics-cards-container {
      grid-template-columns: 1fr; /* One card per row */
  }
}

