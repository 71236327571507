.view-edit-category-modal {
    position: fixed;
    top: 0;
    right: 0;
    width: 690px;
    height: 100vh;
    background-color: var(--white-100, #FFF);
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.1);
    padding: 24px;
    z-index: 1000;
    box-sizing: border-box;
    overflow: hidden;
}

.view-edit-category-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
}

.category-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid var(--black-5, rgba(28, 28, 28, 0.05));
}

.category-header-left {
    display: flex;
    flex-direction: column;
    width: calc(100% - 150px);
}

.category-header-left h2 {
    margin: 0;
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--black-100, #1C1C1C);
    font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
}

.view-edit-category-name {
    color: var(--Primary-Brand, #009A6B);
    font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 300px;
    display: inline-block;
    overflow: hidden;
    text-align: left;
}

.category-name {
    color: var(--black-40, rgba(28, 28, 28, 0.40));
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 8px;
}

.category-header-right {
    display: flex;
    gap: 16px;
    align-items: center;
}

.category-header-right .close-button {
    background: none;
    border: none;
    font-size: 16px;
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0;
    top: 10px;
    cursor: pointer;
    color: var(--black-40, rgba(28, 28, 28, 0.40));
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
}

.category-header-right .close-button:hover {
    background-color: rgba(28, 28, 28, 0.1);
}

.category-header-right .prev-button,
.category-header-right .next-button {
    display: flex;
    align-items: center;
    gap: 4px;
    background: var(--black-5, rgba(28, 28, 28, 0.05));
    color: rgba(28, 28, 28, 1);
    border-radius: 8px;
    padding: 8px 10px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
    border: none;
    margin-top: 32px;
}

.category-header-right .prev-button:hover,
.category-header-right .next-button:hover {
    background-color: rgba(28, 28, 28, 0.1);
}

.category-header-right .prev-button img,
.category-header-right .next-button img {
    opacity: 0.6;
}

.category-header-right .prev-button:disabled,
.category-header-right .next-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.category-body {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 0px;

}

.category-type-title {
    color: var(--black-100, #1C1C1C);
    font-feature-settings: 'ss01' on, 'cv01' on, 'cv11' on;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.category-name-section {
    color: var(--black-100, #1C1C1C);
    font-feature-settings: 'ss01' on, 'cv01' on, 'cv11' on;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}


.category-details-section {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.category-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.category-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.category-info label {
    font-weight: bold;
    color: var(--black-60, rgba(28, 28, 28, 0.60));
    font-size: 12px;
    font-family: Inter, sans-serif;
}

.category-info label {
    color: var(--black-60, rgba(28, 28, 28, 0.60));
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
}

.category-info p {
    color: var(--black-100, #1C1C1C);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
}

.category-footer {
    margin-top: auto;
    padding-top: 16px;
    border-top: 1px solid var(--black-5, rgba(28, 28, 28, 0.05));
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}

/* Add styles for edit mode if needed */
.view-edit-category-modal.editing .category-info input {
    display: flex;
    padding: var(--12, 12px);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    width: 100%;
    box-sizing: border-box;

    border-radius: var(--8, 8px);
    border: 1px solid var(--black-10, rgba(28, 28, 28, 0.10));
    background: var(--white-80, rgba(255, 255, 255, 0.80));

    color: var(--black-100, #1C1C1C);
    font-feature-settings: 'ss01' on, 'cv01' on, 'cv11' on;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

/* Styles for input focus (selected state) */
.view-edit-category-modal.editing .category-info input:focus {
    outline: none;
    border: 1px solid var(--Primary-LightGreen, #BEE7C6);
    box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.05);
}

/* Styles for input placeholder */
.view-edit-category-modal.editing .category-info input::placeholder {
    color: var(--black-40, rgba(28, 28, 28, 0.40));
}

/* Remove the styles that were changing text color to blue */
.view-edit-category-modal.editing .category-info input:not(:placeholder-shown) {
    font-weight: 400; /* Keep the font weight consistent */
}



.category-metrics-section {
    display: flex;
    gap: 16px; /* Space between the cards */
    flex-wrap: wrap; /* Wrap to the next line if necessary */
    justify-content: flex-start; /* Align items to the start */
}


/* Add any additional styles for buttons, dropdowns, or other elements as needed */

/* Mobile-friendly styles */
@media screen and (max-width: 768px) {
    .view-edit-category-modal {
      width: 100%;
      height: 100%;
      padding: 16px;
    }
  
    .category-header {
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 16px;
    }
  
    .category-header-left {
      width: 100%;
    }
  
    .category-header-left h2 {
      font-size: 20px;
      line-height: 28px;
    }
  
    .category-name {
      font-size: 14px;
    }
  
    .category-header-right {
      width: 100%;
      justify-content: space-between;
      margin-top: 16px;
    }
  
    .category-header-right .prev-button,
    .category-header-right .next-button {
      margin-top: 0;
      padding: 8px;
    }
  
    .category-header-right .prev-button span,
    .category-header-right .next-button span {
      display: none; /* Hide text on mobile */
    }
  
    .category-body {
      padding-top: 0;
    }
  
    .category-details-section {
      gap: 12px;
    }
  
    .category-info label {
      font-size: 12px;
    }
  
    .category-info p {
      font-size: 14px;
    }
  
    .category-metrics-section {
      flex-direction: column;
    }
  
    .category-metrics-section > div {
      width: 100%;
      margin-right: 0;
    }
  
    .category-footer {
      padding-top: 16px;
    }
  
    .edit-actions {
        display: flex;
        flex-direction: row; /* Change to row to keep buttons side by side */
        justify-content: space-between; /* Space buttons evenly */
        gap: 8px; /* Reduce gap between buttons */
        width: 100%; /* Ensure full width */
      }
    
      .edit-actions button {
        flex: 1; /* Allow buttons to grow and shrink equally */
        min-width: 0; /* Allow buttons to shrink below their content size if needed */
        padding: 10px; /* Adjust padding for better touch targets */
        font-size: 14px; /* Slightly smaller font size for mobile */
      }
    
      /* Specific styles for save and cancel buttons if needed */
      .edit-actions .save-button,
      .edit-actions .cancel-button {
        max-width: calc(50% - 4px); /* Ensure buttons don't exceed half the container width minus half the gap */
      }
  
    /* Adjustments for edit mode on mobile */
    .view-edit-category-modal.editing .category-info input {
      padding: 8px;
      font-size: 14px;
    }
  }
