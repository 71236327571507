.onboarding-container {
  display: flex;
  height: 100vh;
  position: relative;
}

.onboarding-sidebar {
  width: 450px;
  background-color: #F2F4F7;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 100px; /* Add space for the logo */
}

.suma-logo {
  position: absolute;
  top: 2rem;
  left: 2rem;
  width: 140px;
  z-index: 1000;
}

.steps-container {
  flex-grow: 1;
  margin-top: 30%;
}


.step-icon {
  display: flex;
  margin-right: 1rem;
  width: 48px;
  height: 48px;
  padding: var(--spacing-none, 12px);

  justify-content: center;
  align-items: center;
  border-radius: var(--radius-lg, 10px);
  border: 1px solid var(--Component-colors-Components-Icons-Featured-icons-Modern-featured-icon-modern-border, #E4E7EC);
  background: var(--Colors-Background-bg-primary, #FFF);
  box-shadow: 0px 0px 0px 1px var(--Colors-Effects-Shadows-shadow-skeumorphic-inner-border, rgba(16, 24, 40, 0.18)) inset, 0px -2px 0px 0px var(--Colors-Effects-Shadows-shadow-skeumorphic-inner, rgba(16, 24, 40, 0.05)) inset, 0px 1px 2px 0px var(--Colors-Effects-Shadows-shadow-xs, rgba(16, 24, 40, 0.05));
}

.step-icon:active {
  background-color: var(--Primary-LightGreen, #E6F5EA);
  display: flex;
  width: 48px;
  height: 48px;
  padding: var(--spacing-none, 12px);
  justify-content: center;
  align-items: center;
  border-radius: var(--radius-lg, 10px);
  border: 1px solid var(--Primary-MediumGreen, #79CD8F);
  background: var(--Colors-Background-bg-primary, #FFF);

  /* Shadows/shadow-xs-skeuemorphic */
  box-shadow: 0px 0px 0px 1px var(--Colors-Effects-Shadows-shadow-skeumorphic-inner-border, rgba(16, 24, 40, 0.18)) inset, 0px -2px 0px 0px var(--Colors-Effects-Shadows-shadow-skeumorphic-inner, rgba(16, 24, 40, 0.05)) inset, 0px 1px 2px 0px var(--Colors-Effects-Shadows-shadow-xs, rgba(16, 24, 40, 0.05));
}


.step-icon img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}


.step-item {
  display: flex;
  align-items: center;
  /* Changed back to center for vertical alignment */
  margin-bottom: 0.3rem;
  opacity: 0.5;
  transition: opacity 0.3s ease;
  /* make a pointer as it's now clickable */
  cursor: pointer;
}

.step-item:hover {
  opacity: 1;
}

.step-item.active {
  opacity: 1;
}

.step-item.active .step-icon {
  /* background-color: var(--Primary-LightGreen, #E6F5EA); */
  border: 1px solid var(--Primary-MediumGreen, #79CD8F);

}

.step-connector {
  width: 2px;
  height: 46px;
  /* Adjust this value to control the length of the connector */
  margin-left: 36px;
  /* Half of the step-icon width (48px) minus half of the connector width (2px) */
  background: var(--Colors-Border-border-secondary, #E4E7EC);
  border-radius: var(--spacing-xxs, 2px);
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;

}


/* Add this to color the SVG icons */
.step-icon svg {
  fill: var(--Primary-MediumGreen, #79CD8F);
}

.step-item.active .step-icon svg {
  fill: var(--Primary-Brand, #009A6B);
}

.step-info {
  flex: 1;
  /* Allow the text to take up remaining space */
}

.step-info h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: var(--black-100, #1C1C1C);

  /* Text md/Semibold */
  font-family: var(--Font-family-font-family-body, Inter);
  font-size: var(--Font-size-text-md, 16px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--Line-height-text-md, 24px);
  /* 150% */
}

.step-info p {
  margin: 0;
  font-size: 14px;
  color: #666;
  color: var(--black-60, rgba(28, 28, 28, 0.60));

  /* Text md/Regular */
  font-family: var(--Font-family-font-family-body, Inter);
  font-size: var(--Font-size-text-md, 16px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--Line-height-text-md, 24px);
  /* 150% */
}

.sidebar-footer {
  font-size: 14px;
  color: #666;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
}

.sidebar-footer a {
  color: #009A6B;
  text-decoration: none;
  display: block;
}

.sidebar-footer p {
  margin: 0px;
}

/* Ensure the content is centered and has a consistent width */
.onboarding-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;

}



.step-indicator {
  margin-bottom: 1rem;
  color: #666;
}

.step-indicator.active {
  color: #009a6b;
  font-weight: bold;
}

.continue-button {
  display: flex;
  padding: 10px var(--spacing-xl, 16px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-sm, 6px);
  align-self: stretch;
  border-radius: var(--radius-md, 8px);
  background: var(--Primary-Brand, #009A6B);
  color: var(--Component-colors-Components-Buttons-Primary-button-primary-fg, #FFF);
  border: none;
  cursor: pointer;
  margin-top: 24px;
  margin-bottom: 12px;
  /* Text md/Semibold */
  font-family: var(--Font-family-font-family-body, Inter);
  font-size: var(--Font-size-text-md, 16px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--Line-height-text-md, 24px);
}

.continue-button:hover {
  background-color: #007f5f;
}

.continue-button:disabled,
.skip-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.progress-dots {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--Colors-Border-border-secondary, #E4E7EC);
  margin: 0 4px;
}

.dot.active {
  background-color: var(--Primary-Brand, #009A6B);
}


.step-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-width: 400px;
  max-width: 580px;
}

.step-content h2 {
  text-align: center;
  font-size: 24px;
  /* margin-bottom: 8px; */
  color: var(--black-100, #1C1C1C);
  text-align: center;

  /* Display sm/Semibold */
  font-family: var(--Font-family-font-family-display, Inter);
  font-size: var(--Font-size-display-sm, 30px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--Line-height-display-sm, 38px);
  /* 126.667% */
  margin-bottom: 8px;
}

.step-content input {
  display: flex;
  padding: 10px 14px;
  align-items: center;
  gap: var(--spacing-md, 8px);
  align-self: stretch;
  border-radius: var(--radius-md, 8px);
  border: 1px solid var(--Colors-Border-border-primary, #D0D5DD);
  background: var(--Colors-Background-bg-primary, #FFF);

  /* Shadows/shadow-xs */
  box-shadow: 0px 1px 2px 0px var(--Colors-Effects-Shadows-shadow-xs, rgba(16, 24, 40, 0.05));

  overflow: hidden;
  color: var(--black-80, rgba(28, 28, 28, 0.80));
  text-overflow: ellipsis;

  /* Text md/Regular */
  font-family: var(--Font-family-font-family-body, Inter);
  font-size: var(--Font-size-text-md, 16px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--Line-height-text-md, 24px);
  /* 150% */
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  /* Add smooth transition */
}

.step-content input:focus {
  outline: none;
  /* Remove default focus outline */
  border-color: var(--Primary-LightGreen, #E6F5EA);
  /* Light green border color */
  box-shadow: 0 0 0 4px rgba(230, 245, 234, 0.5);
  /* Add a light green glow */
}

/* Optional: Style for hover state */
.step-content input:hover {
  border-color: var(--Primary-MediumGreen, #79CD8F);
}

.step-content input,
.step-content select {
  margin-top: 4px;
}


.step-description {
  font-size: 16px;
  align-self: stretch;
  margin-bottom: 32px;
  margin-top: 0px;
  color: var(--black-60, rgba(28, 28, 28, 0.60));
  text-align: center;

  /* Text md/Regular */
  font-family: var(--Font-family-font-family-body, Inter);
  font-size: var(--Font-size-text-md, 16px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--Line-height-text-md, 24px);
  /* 150% */
}


.onboarding-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.step-content input {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 14px;
  font-size: 16px;
  border-radius: var(--radius-md, 8px);
  border: 1px solid var(--Colors-Border-border-primary, #D0D5DD);
  background: var(--Colors-Background-bg-primary, #FFF);
  box-shadow: 0px 1px 2px 0px var(--Colors-Effects-Shadows-shadow-xs, rgba(16, 24, 40, 0.05));
}

.step-content input:active,
.step-content input:focus {
  border-color: #009a6b;
}

.step-content button {
  padding: 0.5rem 1rem;
  background-color: #009a6b;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.step-content button:hover {
  background-color: #007f5f;
}


.company-details-form {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.company-details-form label {
  color: var(--black-100, #1C1C1C);
  font-family: var(--Font-family-font-family-body, Inter);
  font-size: var(--Font-size-text-sm, 14px);
  font-style: normal;
  font-weight: 500;
  line-height: var(--Line-height-text-sm, 20px);
}

/* Ensure select elements match the height of input elements */
.company-details-form select {
  appearance: none;
  background-image: url('../../assets/icons/ArrowLineDown.svg');
  background-repeat: no-repeat;
  background-position: right 14px center;
  background-size: 12px auto;
  padding-right: 40px;
  /* Make room for the dropdown arrow */
}


/* Maintain consistency for all inputs and selects */
.company-details-form input,
.company-details-form select {
  width: 100%;
  padding: 10px 14px;
  height: 44px;
  /* Set a fixed height for all input fields */

  border: 1px solid var(--Colors-Border-border-primary, #D0D5DD);
  border-radius: var(--radius-md, 8px);
  font-size: 16px;
  margin-bottom: 0px;
  background: var(--Colors-Background-bg-primary, #FFF);
  box-shadow: 0px 1px 2px 0px var(--Colors-Effects-Shadows-shadow-xs, rgba(16, 24, 40, 0.05));
  box-sizing: border-box;
  /* Ensure padding is included in the height */
}


.company-details-form input:focus,
.company-details-form select:focus {
  outline: none;
  border-color: var(--Primary-LightGreen, #E6F5EA);
  box-shadow: 0 0 0 4px rgba(230, 245, 234, 0.5);
}

/* Make company name input full width */
.company-details-form>label:first-child {
  width: 100%;
}

.company-details-form>label:first-child input {
  width: 100%;
  min-width: 100%;
}

.company-details-form .form-row {
  display: flex;
  gap: 16px;
}

.company-details-form .form-row>label {
  flex: 1;
}

.company-details-form select {
  appearance: none;
  background-image: url('../../assets/icons/ArrowLineDown.svg');
  background-repeat: no-repeat;
  background-position: right 12px top 50%;
  background-size: 20px auto;
}

.continue-button {
  width: 100%;
  padding: 10px 0;
  background-color: var(--Primary-Brand, #009A6B);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 24px;
}

.continue-button:hover {
  background-color: #007f5f;
}

.invite-team-form {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.team-member-row {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}

.input-group {
  flex: 1;
}

.input-group label {
  display: block;
  margin-bottom: 6px;
  color: var(--black-100, #1C1C1C);
  font-family: var(--Font-family-font-family-body, Inter);
  font-size: var(--Font-size-text-sm, 14px);
  font-weight: 500;
}

.input-group input {
  width: 100%;
  padding: 10px 14px;
  border: 1px solid var(--Colors-Border-border-primary, #D0D5DD);
  border-radius: 8px;
  font-size: 16px;
}

.add-member-button {
  padding: 10px 16px;
  background-color: var(--Primary-LightGreen, #E6F5EA);
  color: var(--Primary-Brand, #009A6B);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
}

.add-member-button:hover {
  background-color: #C9F0D9;
}

.button-group {
  margin-top: 24px;
}

.continue-button,
.skip-button {
  width: 100%;
  padding: 10px 0;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 12px;
}

.continue-button {
  background-color: var(--Primary-Brand, #009A6B);
  color: white;
  margin-bottom: 12px;
}

.skip-button {
  background-color: transparent;
  color: var(--black-60, rgba(28, 28, 28, 0.60));
}

.skip-button:hover {
  text-decoration: underline;
  background-color: transparent;
}

.upload-invoices-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto 24px;
}

.upload-invoices-container .modal-overlay {
  position: static;
  background: none;
}

.upload-invoices-container .modal-content {
  position: static;
  transform: none;
  box-shadow: none;
  max-height: none;
  overflow: visible;
}

.upload-invoices-container .close-button {
  display: none;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.back-button,
.continue-button,
.skip-button {
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.back-button {
  background-color: #f0f0f0;
  color: #333;
  border: 1px solid #ccc;
}

.continue-button {
  background-color: var(--Primary-Brand, #009A6B);
  color: white;
  border: none;
}

.skip-button {
  background-color: transparent;
  color: var(--black-60, rgba(28, 28, 28, 0.60));
  border: none;
}

.error-message {
  color: #EE7C30;
  font-size: 0.8em;
  margin-top: 5px;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .onboarding-container {
    flex-direction: column;
    height: auto;
    min-height: 100vh;
  }

  .suma-logo {
    position: absolute;
    top: 2rem;
    left: 2rem;
    width: 140px;
  }

  .onboarding-sidebar {
    display: none; /* Hide the sidebar on mobile */
  }

  .onboarding-content {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 480px;
    min-width: 358px;
    margin: 0 auto;
  }

  .onboarding-container .suma-logo {
    position: static;
    margin-top: 24px;
    margin-left: 32px;
    width: 140px;
    align-self: flex-start;
  }

  .step-content {
    max-width: 480px;
    min-width: 358px;
    width: auto;
  }

  .step-content h2 {
    font-size: 24px;
    text-align: left;
    margin-top: 0px;
    margin-bottom: 4px;
  }

  .step-description {
    font-size: 14px;
    text-align: left;
    margin-bottom: 24px;
  }

  .-details-focompanyrm select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg fill="%23000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 20px;
    padding-right: 30px;
  }

  .company-details-form select,
  .company-details-form option {
    font-size: 16px; /* Increase font size for better readability */
  }

  .company-details-form .form-row {
    flex-direction: column;
  }

  .company-details-form input,
  .company-details-form select,
  .continue-button,
  .skip-button {
    width: 100%;
  }

  .company-details-form select {
    position: relative;
    z-index: 1;
  }

   /* Custom dropdown styling */
   .company-details-form select::-ms-expand {
    display: none;
  }

  .company-details-form select:focus {
    outline: none;
    border-color: var(--Primary-Brand, #009A6B);
    box-shadow: 0 0 0 2px rgba(0, 154, 107, 0.2);
  }

  /* Ensure dropdown appears below the select element */
  .company-details-form select option {
    position:relative;
    z-index: 2;
    background-color: white;
    padding: 10px;
  }

  /* Add some space between form rows */
  .company-details-form .form-row + .form-row {
    margin-top: 16px;
  }

  .team-member-row {
    flex-direction: column;
  }

  .input-group {
    width: 100%;
  }

  .progress-dots {
    margin-top: 2rem;
  }
}


